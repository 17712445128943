import React, { Component } from "react";
import { connect } from 'react-redux';
import http from "../../utils/http";
import {
  Row,
  Col,
  Select,
  Typography,
  Button
} from "antd";
import { FormattedMessage } from 'react-intl';
import { getIntl } from "../../utils/intl";
import TransferQuestions from './TransferQuestions';

const { Option } = Select;
const { Title, Paragraph, Text } = Typography;

class FlowOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal2Visible: false,
      questionTypes: {
        1: "Free text",
        2: "Dropdown",
        3: "Multiple select",
        // 4: "Require signature"
      },
      visitorTypes: [],
      questionRepository: [],
      flows: [
        // {
        //   visitorType: "Business",
        //   flow: [
        //     {
        //       questionType: "Free Text",
        //       text: "Questão 1"
        //     },
        //     {
        //       questionType: "Dropdown",
        //       text: "Questão 2",
        //       options: ["Opt 1", "Opt 2", "Opt 3"]
        //     },
        //     {
        //       questionType: "Free Text",
        //       text: "Questão 3"
        //     }
        //   ]
        // },
        // {
        //   visitorType: "Family",
        //   flow: [
        //     {
        //       questionType: "Free Text",
        //       text: "Questão 1"
        //     },
        //     {
        //       questionType: "Dropdown",
        //       text: "Questão 2",
        //       options: ["Opt 1", "Opt 2", "Opt 3"]
        //     },
        //     {
        //       questionType: "Free Text",
        //       text: "Questão 3"
        //     }
        //   ]
        // }
      ],
      selectedVisitor: null,
      isFlowTouched: false
    };
  }

  async componentDidMount() {
    const { company_id } = this.props.companyConfig;
    try {
      const visitorTypes = (await http.get(`/company/${company_id}/visitor_types`)).data
        .payload;
      const questionRepository = (await http.get(`/company/${company_id}/questions`)).data
        .payload;
      this.setState({
        visitorTypes,
        questionRepository
      });
    } catch (error) {
      console.error(error);
    }
  }

  // setModal2Visible = (isVisible, index, selectedVisitor) => {
  //   if (typeof selectedVisitor !== "undefined" && this.state.flows[selectedVisitor]) {
  //     this.setState({
  //       modal2Visible: isVisible,
  //       questionToEdit: {
  //         content: this.state.flows[selectedVisitor].flow[index],
  //         originalIndex: index
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       modal2Visible: isVisible
  //     });
  //   }
  // };

  selectFlow = index => {
    this.setState({ selectedVisitor: index, isFlowTouched: false });
  };

  renderVisitorTypes = () => {
    return this.state.visitorTypes.map((aVisitor, index) => (
      <Option value={aVisitor.id} key={aVisitor.id}>
        {aVisitor.type}
      </Option>
    ));
  };

  // moveItem = (index, direction, selectedVisitor) => {
  //   const auxFlow = this.state.flows[selectedVisitor];
  //   if (direction === "up" && index > 0) {
  //     [auxFlow.flow[index - 1], auxFlow.flow[index]] = [
  //       auxFlow.flow[index],
  //       auxFlow.flow[index - 1]
  //     ];
  //     this.state.flows[selectedVisitor] = auxFlow;
  //     this.setState({ flows: this.state.flows, isFlowTouched: true });
  //   }
  //   if (direction === "down" && index < auxFlow.flow.length) {
  //     [auxFlow.flow[index], auxFlow.flow[index + 1]] = [
  //       auxFlow.flow[index + 1],
  //       auxFlow.flow[index]
  //     ];
  //     this.state.flows[selectedVisitor] = auxFlow;
  //     this.setState({ flows: this.state.flows, isFlowTouched: true });
  //   }
  // };

  // removeItem = (index, selectedVisitor) => {
  //   this.state.flows[selectedVisitor].flow.splice(index, 1);
  //   this.setState({ flows: this.state.flows, isFlowTouched: true });
  // };

  // renderQuestionEdit = () => {
  //   if (this.state.questionToEdit) {
  //     return (
  //       <React.Fragment>
  //         <Typography level={3} style={{ margin: "12px 0px" }}>
  //           Question Text
  //         </Typography>

  //         <Input
  //           type="text"
  //           placeholder={this.state.questionToEdit.content.text}
  //         />
  //         <Typography level={3} style={{ margin: "12px 0px" }}>
  //           Question Types
  //         </Typography>

  //         <Select
  //           initialValue={this.state.questionToEdit.content.questionType}
  //           style={{ width: 200 }}
  //           placeholder={this.state.questionToEdit.content.questionType}
  //         >
  //           {Object.keys(this.state.questionTypes).map(typeId => {
  //             return (
  //               <Option value={typeId} key={typeId}>
  //                 {this.state.questionTypes[typeId]}
  //               </Option>
  //             );
  //           })}
  //         </Select>
  //       </React.Fragment>
  //     );
  //   }
  // };

  render() {
    const intl = getIntl();
    const visitorTypePlaceholder = intl.formatMessage(
      {
        id: "VisitorQuestionOrderPage.VisitorTypeSelect.Placeholder",
        defaultMessage: "Besuchertyp auswählen"
      }
    );

    return (
      <div className="question-order__wrapper">
        {/* {this.state.questionToEdit !== undefined && (
          <Modal
            title="Edit question"
            centered
            maskClosable
            visible={this.state.modal2Visible}
            onOk={() => this.setModal2Visible(false)}
            onCancel={() => this.setModal2Visible(false)}
            okText="Save"
          >
            {this.renderQuestionEdit()}
          </Modal>
        )} */}
        <Row>
          <Col span={24}>
            <Title level={1} style={{ color: "#4c566a" }}>
              <FormattedMessage id="VisitorQuestionOrderPage.Title1"
                defaultMessage="Organisieren Sie Ihren Fragen-Flow">
              </FormattedMessage>
            </Title>
            <Paragraph>
              <FormattedMessage id="VisitorQuestionOrderPage.VisitorType"
              values={{line_break: <br/>}}>
              </FormattedMessage>
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select
              style={{ width: 200 }}
              placeholder={visitorTypePlaceholder}
              onChange={this.selectFlow}
            >
              {this.renderVisitorTypes()}
            </Select>
            {this.state.isFlowTouched && (
              <Button type="primary" style={{ marginLeft: "12px" }}>
                Save
              </Button>
            )}
          </Col>
        </Row>
        {this.state.selectedVisitor !== null && (
          <Row>
            <Col xs={24} lg={12}>
              <TransferQuestions questionRepository={this.state.questionRepository}
                selectedVisitor={this.state.selectedVisitor}
                allVisitors={this.state.visitorTypes} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};


export default connect(mapStateToProps)(FlowOrder);
