import React, { useEffect, useState } from "react";
import { FormOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";

import http, { getErrorMsg } from "../../../utils/http";
import { displayNotification } from "../../../components/Notification";
import { useSelector } from "react-redux";
import { getIntl } from "../../../utils/intl";

const { Text, Title, Paragraph } = Typography;

function DecisionFlowForm(props) {
  const companyId = useSelector((state) => state.companyConfig.company_id);

  const questionModel = {
    id: null,
    question: "",
  };

  const [question, setQuestion] = useState(questionModel);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [editData, setEditData] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const intl = getIntl();
  const placeholder = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Input.Placeholder",
    defaultMessage: "Geben Sie hier Ihre Willkommensfrage ein.",
  });
  const saveButton = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Save",
    defaultMessage: "Speichern",
  });

  const previousQuestionTitle = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Title",
    defaultMessage: "Zuvor erstellte Frage",
  });

  const previousQuestionBody = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Body",
    defaultMessage:
      "Es scheint, dass eine Willkommensfrage bereits erstellt wurde. Hier können Sie die Frage sehen, bearbeiten oder löschen.",
  });

  const previousQuestion = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous",
    defaultMessage: "Willkommensfrage:",
  });

  const previousQuestionEdit = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Button.Edit",
    defaultMessage: "Bearbeiten",
  });

  const previousQuestionDelete = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Button.Delete",
    defaultMessage: "Löschen",
  });

  const previousQuestionDeleteConfirm = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Button.Delete.Confirm",
    defaultMessage: "Sind Sie sicher, dass Sie dies löschen möchten?",
  });

  const previousQuestionDeleteOk = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Button.Delete.Ok",
    defaultMessage: "Bestätigen",
  });

  const previousQuestionCancel = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Previous.Button.Edit.Cancel",
    defaultMessage: "Abbrechen",
  });

  const welcomQuestionFetchError = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Error.Fetch",
    defaultMessage:
      "Die Daten der Willkommensfrage konnten nicht abgerufen werden. Versuchen Sie es später noch einmal.",
  });

  const welcomQuestionNotificationError = intl.formatMessage({
    id: "Error",
    defaultMessage: "Etwas ist schief gelaufen!",
  });

  const welcomQuestionValidationError = intl.formatMessage({
    id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Validation.Error",
    defaultMessage: "Bitte geben Sie Ihre Willkommensfrage ein!",
  });

  useEffect(() => {
    const fetchDecisionQuestion = async () => {
      setIsLoading(true);
      try {
        const {
          data: { payload },
        } = await http.get(
          `company/${companyId}/visitor_type/welcome/question`
        );
        if (payload.length > 0) {
          setQuestion(payload[0]);
        }
      } catch (error) {
        setHasError(true);
        displayNotification(
          "error",
          welcomQuestionNotificationError,
          welcomQuestionFetchError
        );
      }
      setIsLoading(false);
    };
    fetchDecisionQuestion();
  }, []);

  const createQuestion = async (decisionQuestion) => {
    try {
      const {
        data: { payload },
      } = await http.post(
        `company/${companyId}/visitor_type/welcome/question`,
        {
          question: decisionQuestion,
        }
      );

      setQuestion(payload);

      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Title",
        defaultMessage: "Erfolg",
      });
      const body = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Body",
        defaultMessage: "Ihre Frage wurde gespeichert!",
      });

      displayNotification("success", title, body);
    } catch (error) {
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveError.Title",
        defaultMessage: "Frage konnte nicht gespeichert werden",
      });

      displayNotification("error", title, getErrorMsg(error, intl));
    }
  };

  const editQuestion = async (decisionQuestion) => {
    try {
      const {
        data: { payload },
      } = await http.put(
        `company/${companyId}/visitor_type/welcome/question/${question.id}`,
        {
          question: decisionQuestion,
        }
      );

      setQuestion(payload);

      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Title",
        defaultMessage: "Erfolg",
      });
      const body = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Body",
        defaultMessage: "Ihre Frage wurde gespeichert!",
      });

      displayNotification("success", title, body);
    } catch (error) {
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveError.Title",
        defaultMessage: "Frage konnte nicht gespeichert werden",
      });

      displayNotification("error", title, getErrorMsg(error, intl));
    }
    setEditData(false);
  };

  const onSubmit = async ({ decisionQuestion }) => {
    if (!question.id) {
      await createQuestion(decisionQuestion);
    } else if (question.id) {
      await editQuestion(decisionQuestion);
    }
  };

  const deleteQuestion = async () => {
    try {
      const response = await http.delete(
        `company/${companyId}/visitor_type/welcome/question/${question.id}`
      );

      setQuestion(questionModel);
      setDeleteConfirmation(false);

      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Title",
        defaultMessage: "Erfolg",
      });
      const body = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionDeleteSuccess.Body",
        defaultMessage: "Ihre Frage wurde erfolgreich gelöscht!",
      });

      displayNotification("success", title, body);
    } catch (error) {
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveError.Title",
        defaultMessage: "Frage konnte nicht gespeichert werden",
      });
      displayNotification("error", title, getErrorMsg(error, intl));
    }
  };

  const CreationForm = () => (
    <div>
      <Form
        layout="horizontal"
        initialValues={{
          decisionQuestion: question.question,
        }}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Row gutter={[0, 12]}>
          <Col lg={24} xl={24}>
            <Form.Item
              name="decisionQuestion"
              rules={[
                {
                  required: true,
                  message: welcomQuestionValidationError,
                  whitespace: true,
                  type: "string",
                },
              ]}
            >
              <Input
                name="decisionQuestionInput"
                autoComplete="off"
                prefix={<FormOutlined />}
                placeholder={placeholder}
              />
            </Form.Item>
          </Col>
          <Col lg={24} xl={24}>
            <Space>
              {question.id && editData && (
                <Button type="ghost" onClick={() => setEditData(false)}>
                  {previousQuestionCancel}
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                sytle={{
                  width: "25%",
                }}
              >
                {saveButton}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );

  if (!isLoading && !hasError) {
    if (question.id && !editData) {
      return (
        <Card bordered>
          <Title level={4} style={{ color: "#4c566a" }}>
            {previousQuestionTitle}
          </Title>
          <Paragraph>{previousQuestionBody}</Paragraph>
          <Paragraph strong>
            {previousQuestion} {question.question}
          </Paragraph>

          <Space>
            <Popconfirm
              title={previousQuestionDeleteConfirm}
              onConfirm={deleteQuestion}
              onCancel={() => setDeleteConfirmation(false)}
              open={deleteConfirmation}
              okText={previousQuestionDeleteOk}
              cancelText={previousQuestionCancel}
            >
              <Button
                size="large"
                type="ghost"
                danger
                onClick={() => setDeleteConfirmation(true)}
              >
                {previousQuestionDelete}
              </Button>
            </Popconfirm>
            <Button
              size="large"
              type="primary"
              onClick={() => setEditData(true)}
            >
              {previousQuestionEdit}
            </Button>
          </Space>
        </Card>
      );
    }
    if (!question.id || editData) {
      return <CreationForm />;
    }
  } else if (!isLoading && hasError) {
    return (
      <>
        <Alert type="error" message={welcomQuestionFetchError} />
      </>
    );
  } else if (isLoading) {
    return (
      <>
        <Row justify="center">
          <Col>
            <Spin size="large"></Spin>
          </Col>
        </Row>
      </>
    );
  }

  return null;
}

export default DecisionFlowForm;
