import React, { Component } from "react";
import QueueAnim from "rc-queue-anim";
import { connect } from 'react-redux';
import http from "../utils/http";
import { displayNotification } from '../components/Notification';
import imgBg from "../static/desk_employee.svg";
import { FormattedMessage } from 'react-intl';

import {
  Row,
  Col,
  Typography,
  Upload,
} from "antd";
import { getIntl } from "../utils/intl";
import { CloudUploadOutlined } from "@ant-design/icons";
import { getCookie } from "../components/getCookie";

const { Title, Paragraph, Text } = Typography;
const { Dragger } = Upload;

class UploadEmployees extends Component {
  constructor(props) {
    super(props);
    const { company_id } = this.props.companyConfig;
    const access_token = getCookie("_a");
    this.draggerOpts = {
      name: "employees",
      multiple: false,
      headers: {
        Authorization: `Bearer ${access_token}`
      },
      action: `${http.ENDPOINT}/company/${company_id}/employees`,
      data: {
        company_id: this.props.companyConfig.company_id,
      },
      showUploadList: false,
      onChange(info) {
        const { status } = info.file;
        const intl = getIntl();
        if (status === "done") {
          const title = intl.formatMessage(
            {
              id: "UploadEmployeesPage.Notification.UploadSuccess.Title",
              defaultMessage: "Erfolg!"
            }
          );
          const body = `${info.file.name}` + ' ' + intl.formatMessage(
            {
              id: "UploadEmployeesPage.Notification.UploadSuccess.Body",
              defaultMessage: "Datei wurde hochgeladen"
            }
          );
          displayNotification("success", title, body);
        } else if (status === "error") {
          if (info.file.response && info.file.response.error) {
            const title = `${info.file.name}` + ' ' + intl.formatMessage(
              {
                id: "UploadEmployeesPage.Notification.UploadFail.Title",
                defaultMessage: "Datei-Upload fehlgeschlagen"
              }
            );
            const firstLine = intl.formatMessage(
              {
                id: "UploadEmployeesPage.Notification.UploadFail.Body1",
                defaultMessage: "Hier sind die Gründe für Sie:",
                values: <br />
              }
            );
            const lastLine = intl.formatMessage(
              {
                id: "UploadEmployeesPage.Notification.UploadFail.Body2",
                defaultMessage: `\nWenn das Problem weiterhin besteht, 
                bitte kontaktieren Sie unser freundliches Support-Team! :)`,
                values: <br />
              }
            );
            const errors = info.file.response.error;
            let errorDesc = errors ? firstLine : "";
            Object.entries(errors).map(([key, value]) => {
              errorDesc += value ? `${key} - ${value[0]} \n` : "\n";
            });
            errorDesc += lastLine;
            displayNotification("error", title, errorDesc);
          }
        }
      }
    };
  }

  render() {
    return (
      <div>
        <Row key="1" gutter={24}>
          <Col md={0} lg={4} xl={8} />
          <Col md={24} lg={14} xl={8}>
            <QueueAnim
              delay={500}
              interval={750}
              duration={1000}
              className="fade-in"
            >
              <img
                src={imgBg}
                alt=""
                style={{
                  display: "block",
                  margin: "auto",
                  width: "80%"
                }}
                className="opacity-5"
                key="a"
              />
              <Title key="b" level={4} style={{ color: "#4c566a" }}>
                <FormattedMessage id="UploadEmployeesPage.Header"
                  defaultMessage="Hier haben Sie die Möglichkeit Ihre Mitarbeiter hinzuzufügen!"
                >
                </FormattedMessage>
              </Title>
              <Paragraph key="c">
                <FormattedMessage id="UploadEmployeesPage.Body1"
                  defaultMessage="In diesem Abschnitt können Sie Ihre CSV-Datei mit den Mitarbeiterinformationen
                Ihres Unternehmens hochladen.
                Bitte beachten Sie, dass Ihre CSV-Datei folgende Spalten enthalten muss:">
                </FormattedMessage>
                <Text code>first_name</Text>,
                <Text code>last_name</Text> and
                <Text code>email</Text>.
              </Paragraph>
              <Paragraph key="d">
                <FormattedMessage id="UploadEmployeesPage.Body2"
                  defaultMessage="Zusätzlich können Sie auch gerne die Spalten:">
                </FormattedMessage>
                <Text code>designation</Text>,
                <Text code>role</Text> and
                <Text code>phone_number</Text>.
              </Paragraph>
              <Dragger {...this.draggerOpts} key="e">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  <FormattedMessage id="UploadEmployeesPage.UploadArea.Title"
                    defaultMessage="Klicken oder ziehen Sie Ihre Datei zum Hochladen in diesen Bereich">
                  </FormattedMessage>
                </p>
                <p className="ant-upload-hint">
                  <FormattedMessage id="UploadEmployeesPage.UploadArea.Body"
                    defaultMessage="Bitte laden Sie eine CSV-Datei hoch, welche Ihre aktuellen Mitarbeiterdaten enthält.
                  Es kann maximal eine Datei hochgeladen werden.">
                  </FormattedMessage>
                </p>
              </Dragger>
            </QueueAnim>
          </Col>
          <Col md={0} lg={2} xl={8} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};

export default connect(mapStateToProps)(UploadEmployees);
