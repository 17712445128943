import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Col, Row, Space, Typography} from "antd";
import {
    CloudDownloadOutlined, LaptopOutlined, LinkedinFilled, FormOutlined, SendOutlined, SolutionOutlined,
    ProfileOutlined
} from "@ant-design/icons";

import Disclaimer from "../../Disclaimer";
import http from "../../../../../../utils/http";
import {sendEvent} from '../../../../../../utils/amplitude';
import {
    FacebookOutlined, InstagramOutlined, LinkOutlined, MailOutlined, PhoneOutlined, TwitterOutlined, WhatsAppOutlined,
    
} from "@ant-design/icons";
import GoogleTranslator from "../../GoogleTranslator";
import CoverLoading from "../../CoverLoading";
import SEOPreMenuPage from "./SEOPreMenuPage";
import Certifications from "../../Certifications";
import vCard from 'vcf';
import LinkButtonType from "./LinkButtonType";


const {Title, Paragraph} = Typography;

export const generateLogo = (companyLogo) => {
    return companyLogo ? (<div
        className="logo-public"
        style={{
            backgroundImage: `url(${companyLogo})`,
        }}
    ></div>) : (<div className="logo-public"></div>);
};


const RenderLinks = ({isPreview = false}) => {
    const ICONS = {
        'link': <LinkOutlined/>,
        'facebook': <FacebookOutlined/>,
        'instagram': <InstagramOutlined/>,
        'email': <MailOutlined/>,
        'phone': <PhoneOutlined/>,
        'twitter': <TwitterOutlined/>,
        'menu': <SolutionOutlined/>,
        'registration': <FormOutlined/>,
        'whatsapp': <WhatsAppOutlined/>,
        'linkedin': <LinkedinFilled/>,
        'file': <CloudDownloadOutlined/>,
        'upload': <CloudDownloadOutlined/>,
        'newsletter': <SendOutlined/>,
        'software': <LaptopOutlined/>,
        'product_and_services': <ProfileOutlined/>,
    };

    const internalTypes = ['menu', 'registration', 'newsletter', 'product_and_services'];

    const prependProtocol = (link) => {
        let _link = link;
        if (_link.indexOf('https://') === 0) return _link; else if (_link.indexOf('http://') === 0) {
            _link = (_link.split('http://'))[1];
        }
        return `https://${_link}`
    };

    const links = useSelector(state => state.companyConfig.links);
    const menuType = useSelector(state => state.digitalMenuConfig.type);

    if (links.length > 0) {

        const socialLinks = ['whatsapp', 'instagram', 'facebook', 'twitter', 'linkedin', 'phone', 'email'];
        const visibleLinks = links.filter(aLink => aLink.visible);
        const textLinks = visibleLinks.filter(aLink => aLink.variation === 'text');
        const iconSocialLinks = visibleLinks.filter(aLink => ((socialLinks.indexOf(aLink.type) >= 0) && aLink.variation === 'small'));

        const smallLinks = (<Row justify="center">
            <Col xs={0} md={isPreview ? 0 : 7}></Col>
            <Space>
                {iconSocialLinks.map((aLink, index) => {
                    let linkAddress = prependProtocol(aLink.link);
                    if (aLink.type === 'phone') {
                        linkAddress = `tel:${aLink.link}`;
                    } else if (aLink.type === 'email') {
                        linkAddress = `mailto:${aLink.link}`;
                    }

                    return (<Col>
                        <a
                            target={internalTypes.indexOf(aLink.type) >= 0 ? "" : "_blank"}
                            rel="noreferrer nofollowing ugc"
                            key={`link-${index}-${aLink.id}`}
                            href={linkAddress}
                            // Prevent clicking on Preview Mode
                            style={{pointerEvents: isPreview ? 'none' : 'auto'}}
                        >
                            <div className={`m-t-12`}>
                                <button className="premenu__social-icons pointer">
                                    <span>
                                        {ICONS[aLink.type]}
                                    </span>
                                </button>
                            </div>
                        </a>
                    </Col>)
                })}
            </Space>
            <Col xs={0} md={isPreview ? 0 : 7}></Col>
        </Row>);

        const buttonLinks = textLinks.map((aLink, index, linksList) => {
            let linkAddress = aLink.link;
            if (aLink.type === 'menu' && aLink.link.includes('welcomyapp') && menuType !== 'digital') {
                linkAddress = linkAddress.replace('menu', 'static');
            } else if (aLink.type === 'phone') {
                linkAddress = `tel:${aLink.link}`;
            } else if (aLink.type === 'email') {
                linkAddress = `mailto:${aLink.link}`; 
            } else {
                linkAddress = aLink.link || prependProtocol(aLink.link);
            }

            return (<Row>
                <Col xs={0} md={isPreview ? 0 : 7}></Col>
                <Col xs={24} md={isPreview ? 24 : 10}>
                    <LinkButtonType
                        internalTypes={internalTypes}
                        aLink={aLink}
                        index={index}
                        linkAddress={linkAddress}
                        icon={ICONS[aLink.type]}
                        linksList={linksList}
                        isPreview={isPreview}
                    />
                </Col>
                <Col xs={0} md={isPreview ? 0 : 7}></Col>
            </Row>)
        });

        return [smallLinks, ...buttonLinks];
    }

    return null;
}

function PreMenu({isPreview = false}) {
    const [enableContactDownload, setEnableContactDownload] = useState(false);
    const companyConfig = useSelector(state => state.companyConfig);
    const { short_link } = companyConfig;

    /**
    * Redux states
    */
    const verticalId = useSelector(state => state.companyConfig.vertical_id);

    const companyLogo = useSelector(state => state.companyConfig.company_logo);
    const hubLogo = useSelector(state => state.hubConfig.hubLogo);
    const logo = hubLogo || companyLogo;

    const companyName = useSelector(state => state.companyConfig.name);
    const hubName = useSelector(state => state.hubConfig.name);

    const name = hubName || companyName;

    const hubTitle = useSelector(state => state.hubConfig.title);
    const hubSubtitle = useSelector(state => state.hubConfig.subtitle);

    const company_id = useSelector(state => state.companyConfig.company_id);
    const canonical = `${window.location.origin}${window.location.pathname}` // Here only for SEO
    const amplId = useSelector(state => state.companyConfig.amplId); // Amplitude tracking ID
    

    // Data for VCard
    const phoneNumber = companyConfig.links.filter((link) => (link.type === 'phone' && link.to_vcard));
    const phoneNumberCheck = phoneNumber.length ? phoneNumber[0].link.split(' ').join('') : null
    const email = companyConfig.links.filter(link => (link.type === 'email' && link.to_vcard));
    const emailCheck = email.length ? email[0].link.split("?subject")[0] : null;
    const generateVCard = () => {
        if (name && phoneNumber) {
            const card = new vCard();
            card.add('fn', name, { encoding: 'utf-8'});
            card.add('tel', phoneNumberCheck, { type: ['work', 'voice']});
            card.add('url', short_link);
            card.add('org', name, { encoding: 'utf-8'});
            card.add('email', emailCheck);

            const vCardString = card.toString('3.0');
            const blob = new Blob([vCardString], {type: 'text/vcard'});
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'contact.vcf'; // Specify the filename
            a.click();
            URL.revokeObjectURL(url);
        }
    };

    // const [config, setConfig] = useState({});

    /**
     * Animation States
     *
     * Used for displaying the cover loading with animated logo
     */
    const [showCoverLoading, setShowCoverLoading] = useState(true);

    /**
     * Loading animation controller
     *
     * Give user a feedback behaviour from the UI. Used to build trust
     * on the visitor.
     */
    useEffect(() => {
        if (showCoverLoading) {
            setTimeout(async () => {
                setShowCoverLoading(false);
            }, 4000);
        }
    }, [showCoverLoading]);


    /**
     * Assing public cookie to allow /public queries to fetch data for some amount of time
     */
    const assignPublicCookie = async () => {
        if (company_id) {
            await http.get(`/company/${company_id}/assign_token`);
        }
    }

    useEffect(() => {
        (async () => await assignPublicCookie())();
    }, []);

    useEffect(() => {
        setEnableContactDownload(!!(name && (phoneNumber.length || email.length)));
    }, [name, phoneNumber, email]);

    /**
     * Amplitude tracking event emitter
     */
    sendEvent(amplId, 'WelcomyHub_Loaded', {
        'customer': name, 'customerId': company_id, 'vertical': verticalId, 'page': 'welcomyhub',
    });

    const HubSubtitle = () => {
        if (hubSubtitle) return (<div className="premenu-title__description__wrapper">
            <Paragraph
                className="premenu-title__head-description"
                ellipsis={{
                    rows: 3, expandable: true, symbol: <span>Read more</span>,
                }}
            >
                {hubSubtitle}
            </Paragraph>
        </div>);
        return null;
    };

    

    return (<div className="relative">
        {!isPreview && (
            <>
                <SEOPreMenuPage companyName={name} canonical={canonical} companyLogo={logo}/>
                {/*
                Some classes are defined in tabet.scss and content.scss
                */}
                {(logo && showCoverLoading) && <CoverLoading logo={generateLogo(logo)}/>}
            </>
        )}
        <div className="premenu with-logo-wrapper" style={isPreview ? {minHeight: 'auto'} : {}}>
            <div className="top-wrapper premenu--fix-flex">
                <div className="premenu__translation">
                    { isPreview ? null : <GoogleTranslator/> }
                </div>
                <div className="premenu__logo-wrapper">
                    {generateLogo(logo)}
                </div>
                <div className="premenu-title__wrapper">
                    <div className="text-center premenu-title">

                        <Title level={4} className="m-b-0">
                            {hubTitle}
                        </Title>
                        <Title level={3} className="m-t-0 m-b-0">
                            {name}
                        </Title>
                        <HubSubtitle/>
                    </div>
                </div>
            </div>
            <div className="center-wrapper premenu--expand-flex">
                {enableContactDownload &&
                    <div className="neumorphic download-vcard">
                        <button className="neumorphic-button" onClick={generateVCard}>Zu Kontakten hinzufügen
                        </button>
                    </div>}

                <div className="formwrapper" style={isPreview ? {minHeight: '300px'} : {}}>
                    <RenderLinks isPreview={isPreview}/>
                </div>
                <div className="premenu__certifications">
                    <Certifications/>
                </div>
            </div>
            <div className="bottom-wrapper premenu--fix-flex m-t-24 m-b-64">
                <Disclaimer isPreview={isPreview}/>
            </div>
        </div>
    </div>);
}

export default PreMenu;
