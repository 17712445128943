import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  DatePicker,
  Table,
  Button,
  Input,
  Dropdown,
  Menu,
  Tag,
  Tooltip,
  Card,
  Skeleton,
  Spin,
} from "antd";
import {
  SearchOutlined,
  DownOutlined,
  InfoCircleOutlined,
  RightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import http, { getErrorMsg } from "../../utils/http";
import { getIntl } from "../../utils/intl";
import { displayNotification } from '../../components/Notification';
import UserInfoDrawer from "../../components/UserInfoDrawer.jsx";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import intersectionBy from 'lodash/intersectionBy';
import isFunction from 'lodash/isFunction';
import DataCharts from "../../components/DataCharts.jsx";
import downloader from "../../components/Downloader";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

class VisitorLog extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      showVisitorInfo: false,
      totalAlerts: 0,
      alertsWidgetError: false,
      totalVisitorCount: 0,
      currentVisitorCount: 0,
      countVistorsExpected: 0, // As we currently cannot schedule meetings, this variable is here just a reminder that it should be implemented
      visitorsList: [],
      filteredVisitorsList: [],
      selectedVisitor: null,
      selectedDate: [moment().local().startOf('day'), moment().local().endOf('day')],
      userTypes: [],
      selectedUserType: null,
      revealName: null,
      revealEmail: null,
    };
    this.searchInput = createRef();
    this.filters = {};
  }

  componentDidMount() {
    this.fetchList(this.state.selectedDate);
    this.fetchAlerts();
  }

  fetchAlerts = async () => {
    const { company_id } = this.props.companyConfig;
    if (company_id) {
      const intl = getIntl();
      try {
        const response = await http.get(`/company/${company_id}/emergencies`, {
          params: {
            emergency_count_only: true
          }
        });
        
        if (response.status === 200) {
          const alerts = response.data.payload;
          this.setState({
            totalAlerts: alerts.active_emergency_count,
          });
        } else {
          const title = intl.formatMessage({
            id: "Error",
            defaultMessage: "Etwas ist schief gelaufen!",
          });
          const body = intl.formatMessage({
            id: "AlertPage.Notification.Error",
            defaultMessage: "Not able to retrieve alerts from server.",
          });
          displayNotification("error", title, body);
          this.setState({
            alertsWidgetError: true,
          });
        }
      } catch (error) {
        const title = intl.formatMessage({
          id: "Error",
          defaultMessage: "Etwas ist schief gelaufen!",
        });
        const body = intl.formatMessage({
          id: "AlertPage.Notification.Error",
          defaultMessage: "Not able to retrieve emergency alerts from server.",
        });
        displayNotification('error', title, body);
        this.setState({
          alertsWidgetError: true,
        });
      }
    }
  }

  disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().local().endOf("day");
  };

  dateChange = async (date) => {
    if (!date) return;
    const from = date[0].local().startOf('day');
    const to = date[1].local().endOf('day');
    if (this.searchInput.current !== null) {
      this.searchInput.current.value = '';
      this.searchInput.current.state.value = '';
    }

    this.setState({
      selectedDate: [from, to],
      selectedUserType: null,
      userTypes: [],
    });
    this.fetchList([from, to]);
  };

  parseUserTypes = (list, userTypes) => {
    const types = userTypes.reduce((acc, type) => {
      acc[type.id] = type.type;
      return acc;
    }, {});

    const parsed = list.map(aUser => {
      aUser.visitor_type = types[aUser.visitor_type_id];
      return aUser;
    });
    return parsed;
  };

  fetchList = async (isoDate) => {
    const { company_id } = this.props.companyConfig;
    const startTimestamp = isoDate[0].clone();
    const endTimestamp = isoDate[1].clone();
    const params = {
      start: startTimestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
      end: endTimestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
      limit: 9999,
    };
    if (company_id) {
      try {
        const result = await http.get(`company/${company_id}/visitors`, {
          params,
        });
        let list = result.data.payload.entries.map(entry => ({ ...entry, key: `${entry.id}-${entry.sign_in_time}` }));
        let totalVisitorCount = result.data.payload.total_count;
        let currentVisitorCount = result.data.payload.current_count;
        const userTypes = await (await http.get(`/company/${company_id}/visitor_types`)).data.payload;

        if (list.length > 0) {
          list = this.parseUserTypes(list, userTypes);
          this.setState({
            visitorsList: list,
            filteredVisitorsList: list,
            totalVisitorCount: totalVisitorCount,
            currentVisitorCount: currentVisitorCount,
            userTypes,
          });
        }
        else {
          this.setState({
            visitorsList: [],
            filteredVisitorsList: [],
            totalVisitorCount: totalVisitorCount,
            currentVisitorCount: currentVisitorCount,
          });
        }
      } catch (error) {
        console.error(error);
        const intl = getIntl();
        const title = intl.formatMessage(
          {
            id: "VisitorLogPage.Notification.LogFetchError.Title",
            defaultMessage: "Besucherprotokoll konnte nicht abgerufen werden"
          }
        );
        displayNotification("error", title, getErrorMsg(error, intl));
      }
    }
  };

  export = async () => {
    const intl = getIntl();
    let header = "Sign in Time, Sign out Time, Visitor Type, Full Name, E-mail,";
    const NoAnswer = intl.formatMessage(
      {
        id: "NoAnswer",
        defaultMessage: "Keine Angabe"
      }
    );
    const NotInFlow = intl.formatMessage(
      {
        id: "NotApplicable",
        defaultMessage: "Nicht zutreffend"
      }
    );
    const { visitorsList } = this.state;
    if (visitorsList.length > 0) {
      // FETCH ALL QUESTIONS FOR COMPANY
      let questions = [];
      try {
        questions = await (await http.get(`/company/${this.props.companyConfig.company_id}/visitor_types/questions`)).data.payload;
      } catch (error) {
        console.error(error);
      }

      // ORGANIZE MATRIX
      const questionsTexts = (questions.map((aQuestion) => aQuestion.question)).filter((aText, index, original) => (original.indexOf(aText) === index));
      const questionsIds = (questions.map((aQuestion) => aQuestion.id)).filter((aId, index, original) => (original.indexOf(aId) === index));
      header = `${header} ${questionsTexts.join(',')} \n`
      const answers = {}
      for (let id in questionsIds) {
        answers[questionsIds[id]] = [];
      }

      visitorsList.map((aVisitor) => {
        const ids = [].concat(questionsIds);
        const ansIds = aVisitor.answers.map((ans) => ans.question_id);
        ids.map((aId, index) => {
          const indexOf = ansIds.indexOf(aId);
          if (indexOf !== -1) {
            if (
              aVisitor.answers[indexOf].value === null
              ||aVisitor.answers[indexOf].value.length === 0
            ) {
              answers[aId].push(NoAnswer);
            }
            else if (aVisitor.answers[indexOf].value.length === 1) {
              answers[aId].push(aVisitor.answers[indexOf].value[0]);
            } else {
              answers[aId].push((aVisitor.answers[indexOf].value.filter(option => option.checked).map(opt => opt.text)).join('|'));
            }
          } else {
            answers[aId].push(NotInFlow);
          }
        });
      });

      const data = visitorsList
        .map((visitor, index) => {
          const anonymousReason = intl.formatMessage(
            {
              id: "VisitorLogPage.Anonymized.Reason",
              defaultMessage: "Die Besucherdaten wurden auf Ihre Anfrage hin gelöscht.",
            }
          );
          const isAnon = visitor.pseudonymized;
          const anonText = anonymousReason;
          let row = `${moment
              .utc(visitor.sign_in_time)
              .local()
              .format("DD-MM-YYYY HH:mm:ss")} \
                  ,${visitor.sign_out_time !== null
              ? moment
                .utc(visitor.sign_out_time)
                .local()
                .format("DD-MM-YYYY HH:mm:ss")
              : NoAnswer
            },${visitor.visitor_type} \
            ,${isAnon ? 'Anonymous': visitor.full_name} \
            ,${isAnon ? anonText: visitor.email}`;

            for (let id in answers) {
              row = `${row}, ${answers[id][index]}`;
            }

            row = `${row}`;
            return row;

        }).join('\n');


      const toExport = header + data;

      downloader(
        toExport,
        `${moment(this.state.selectedDate[0]).format("DD-MM-YYYY")}-${moment(this.state.selectedDate[1]).format("DD-MM-YYYY")}`
      );

    } else {
      const title = intl.formatMessage(
        {
          id: "VisitorLogPage.Notification.ExportCSV.Title",
          defaultMessage: "Exportfehler"
        }
      );
      const message = intl.formatMessage(
        {
          id: "VisitorLogPage.Notification.ExportCSV.Message",
          defaultMessage: "Keine Daten zum Exportieren!"
        }
      );
      displayNotification("error", title, message);
    }
  };

  showDrawer = (visitor) => {
    this.setState({
      showVisitorInfo: true,
      selectedVisitor: visitor,
    });
  };

  onClose = (callback) => {
    this.setState({
      showVisitorInfo: false,
    }, () => {
      if (callback && isFunction(callback)) {
        callback();
      }
    });
  };

  applyFilter = async (aFilter) => {
    // Defer input search
    // This is useful in case the user is using
    // the free text search.
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      const [ filterType, filterResult ] = await aFilter; 
      this.filters[filterType] = filterResult;
      let allFiltersResult = Object.keys(this.filters).reduce((acc, key) => {
        const result = this.filters[key];
        if (acc.length === 0) {
          acc.push(result);
          return acc;
        } 
        acc[0] = intersectionBy(acc[0], result, 'id');
        return acc;
      }, []);
      this.setState({
        filteredVisitorsList: allFiltersResult[0],
      });
    }, 300);
  }

  search = (query) => {
    return new Promise ((resolve, reject) => {
        // Free text search function
        const { visitorsList } = this.state;
        if (query.length === 0) {
          resolve(['query', visitorsList]);
        }
  
        let _query = query.toLowerCase().trim()
        let list = visitorsList;

        resolve(['query', list.filter((user) => {
  
          return (
            user.full_name.toLowerCase().trim().indexOf(_query) !== -1
            || user.email.toLowerCase().trim().indexOf(_query) !== -1
            || user.visitor_type.toLowerCase().trim().indexOf(_query) !== -1
          );
        })]);
    });
  };

  filterByUserType = (type, name) => {
    return new Promise ((resolve, reject) => {
      const filtered = this.state.visitorsList.filter((user) => user.visitor_type_id === type);
      this.setState({
        selectedUserType: name,
      }, () => {
        resolve(['typeFilter', filtered]);
      });
    });
  }

  checkForOneFilters = () => {
    const keys = Object.keys(this.filters);
    if (keys.length === 0) {
      this.setState({
        filteredVisitorsList: this.state.visitorsList,
      })
    } else if (keys.length === 1) {
      this.setState({
        filteredVisitorsList: this.filters[keys],
      });
    }
    /**
     *
     * For now we just support two types of filters for the
     * visitor list. When more filters are added, this function
     * needs to be revisited (for sure).
     * 
     */
  };

  resetFilter = type => {
    delete this.filters[type];
    this.checkForOneFilters();
  }

  createDropdown = () => {
    let items;
    if (this.state.userTypes.length === 0) {
      items = [
        (<Menu.Item key="no-user-to-filter">
          <span>No Entries</span>
        </Menu.Item>)
      ];
    } else {
      items = this.state.userTypes.map(type => (
        <Menu.Item key={type.id} onClick={() => this.applyFilter(this.filterByUserType(type.id, type.type))}>
          <span>
            {type.type}
          </span>
        </Menu.Item>
      ));
    }
    return (
      <Menu>{items}</Menu>
    );
  }

  signout = async (record) => {
    const { company_id } = this.props.companyConfig;

    http
      .post(`/company/${company_id}/visitors/${record.id}`, {
        sign_out_time: null,
        visitor_type_id: record.visitor_type_id,
      })
      .then((result) => {
        if (result.status === 200) {
          record.sign_out_time = result.data.payload.date;
          this.setState({
            selectedVisitor: record,
          });
        }
        const startTimestamp = this.state.selectedDate[0].clone();
        const endTimestamp = this.state.selectedDate[1].clone();
        const params = {
          start: startTimestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
          end: endTimestamp.endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
          current_count: true
        };
        http
          .get(`/company/${company_id}/visitors`, { params })
          .then((stats_response) => {
            if (stats_response.status === 200) {
              this.setState({
                currentVisitorCount: stats_response.data.payload.current_count
              });
            }
          })
          .catch((error) => {
            console.error(error);
            const intl = getIntl();
            const title = intl.formatMessage(
              {
                id: "VisitorLogPage.Notification.CurrentVisitorCountError.Title",
                defaultMessage: "Besucherzahl konnte nicht abgerufen werden"
              }
            );
            displayNotification("error", title, getErrorMsg(error, intl));
          })
      })
      .catch((error) => {
        console.error(error);
        const intl = getIntl();
        const title = intl.formatMessage(
          {
            id: "VisitorLogPage.Notification.VisitorSignOut.Title",
            defaultMessage: "Konnte den Besucher nicht abmelden"
          }
        );
        const errorResp = getErrorMsg(error, intl, true);
        if (errorResp && errorResp['type'] && errorResp['type'] === 'visitor_signed_out') {
          const errorDescription = intl.formatMessage(
            {
              id: "VisitorLogPage.Notification.VisitorSignOut.Body",
              defaultMessage: "Besucher hat sich bereits abgemeldet"
            }
          )
          displayNotification("error", title, errorDescription);
        }
        else {
          displayNotification("error", title, getErrorMsg(error, intl));
        }
      })
  };

  render() {
    this.createDropdown();
    const intl = getIntl();
    const headerSerialNumber = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.SerialNumber",
        defaultMessage: "#"
      }
    );
    const headerName = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.Name",
        defaultMessage: "Name"
      }
    );
    const headerEmail = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.Email",
        defaultMessage: "Email"
      }
    );
    const headerVisitorType = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.VisitorType",
        defaultMessage: "Besuchertyp"
      }
    );
    const headerSigninTime = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.SignInTime",
        defaultMessage: "Anmeldezeit"
      }
    );
    const headerSignoutTime = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.SignOutTime",
        defaultMessage: "Abmeldezeit"
      }
    );
    const headerMeetingWith = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.MeetingWith",
        defaultMessage: "Meeting mit"
      }
    );
    const noSignout = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.Body.NoSignout",
        defaultMessage: "Kundenanfrage in Bearbeitung"
      }
    );
    const headerActions = intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.Actions",
        defaultMessage: "Aktionen"
      }
    );
    const search = intl.formatMessage(
      {
        id: "VisitorLogPage.Filters.Search",
        defaultMessage: "Suche"
      }
    );
    const filterBy = intl.formatMessage(
      {
        id: "VisitorLogPage.Filters.ByType",
        defaultMessage: "Filter Besuchertypen"
      }
    );

    const anonymous = intl.formatMessage(
      {
        id: "Anonymous",
        defaultMessage: "Anonym",
      }
    );

    const anonymousReason = intl.formatMessage(
      {
        id: "VisitorLogPage.Anonymized.Reason",
        defaultMessage: "Die Besucherdaten wurden auf Ihre Anfrage hin gelöscht.",
      }
    );

    const loading = intl.formatMessage(
      {
        id: "Loading",
        defaultMessage: "Wird geladen",
      }
    );

    const reportCardTitle = intl.formatMessage(
      {
        id: "VisitorLogPage.Report.Card.Title",
        defaultMessage: "Zeitfenster wählen"
      }
    )
    const { vertical_id } = this.props.companyConfig;
    let visitorlogTitle = "VisitorLogPage.Title";
    let notCheckedOut = "VisitorLogPage.VisitorCount.NotCheckedOut";
    if (vertical_id && vertical_id === 4) {
      visitorlogTitle = "VisitorLogPage.Business.Title";
      notCheckedOut = "VisitorLogPage.VisitorCount.Business.NotCheckedOut";
    }
    const columns = [
      {
        title: headerSerialNumber,
        key: "serial_number",
        render: (_, __, index) => (
          <Text level={4}>{this.state.totalVisitorCount - index}</Text>
        ),
      },
      {
        title: headerName,
        dataIndex: "full_name",
        key: "full_name",
        render: (text, record, index) => {
          const isSame = this.state.revealName === index;
            let isSplitted = isSame ? true : false;
            let _text = text;
            if (!text) return
            try {
              const splitted = text.split(" ");
              if (
                splitted.length > 1 &&
                !record.pseudonymized &&
                this.state.revealName !== index
              ) {
                let _first = splitted[0];
                let _second = "******";
                _text = `${_first} ${_second}`;
                isSplitted = true;
              }
            } catch (error) {
              console.error("Error processing name", error);
            }
          return (
            <React.Fragment>
              {!record.pseudonymized && (
                <span>
                  <Text>{_text}</Text>
                  {!isSame && isSplitted && (
                    <EyeOutlined
                      className="showField"
                      onClick={() => {
                        this.setState({ revealName: index });
                      }}
                    />
                  )}
                  {isSame && isSplitted && (
                    <EyeInvisibleOutlined
                      className="showField"
                      onClick={() => {
                        this.setState({ revealName: null });
                      }}
                    />
                  )}
                </span>
              )}
              {record.pseudonymized && <Text strong>{anonymous}</Text>}
            </React.Fragment>
          );
        },
      },
      // @TODO
      //
      // REMOVED UNTIL GDPR GUIDANCE
      //
      // {
      //   title: headerEmail,
      //   dataIndex: "email",
      //   key: "email",
      //   render: (text, record, index) => {
      //     const isSame = this.state.revealEmail === index;
      //     let isSplitted = isSame ? true : false;
      //     let _text = text;
      //     try {
      //       const splitted = text.split("@");
      //       if (
      //         splitted.length === 2 &&
      //         !record.pseudonymized &&
      //         this.state.revealEmail !== index
      //       ) {
      //         let _first = "******";
      //         let _second = splitted[1];
      //         _text = `${_first}@${_second}`;
      //         isSplitted = true;
      //       }
      //     } catch (error) {
      //       console.error("Not an e-mail", error);
      //     }

      //     return (
      //       <>
      //         {!record.pseudonymized && (
      //           <span>
      //             <Text>{_text}</Text>{" "}
      //             {!isSame && isSplitted && (
      //               <EyeOutlined
      //                 className="showField"
      //                 onClick={() => {
      //                   this.setState({ revealEmail: index });
      //                 }}
      //               />
      //             )}
      //             {isSame && isSplitted && (
      //               <EyeInvisibleOutlined
      //                 className="showField"
      //                 onClick={() => {
      //                   this.setState({ revealEmail: null });
      //                 }}
      //               />
      //             )}
      //           </span>
      //         )}
      //         {record.pseudonymized && <Text>{anonymousReason}</Text>}
      //       </>
      //     );
      //   },
      // },
      {
        title: headerVisitorType,
        dataIndex: "visitor_type",
        key: "visitor_type",
        render: (text) => <Text level={4}>{text}</Text>,
      },
      {
        title: headerSigninTime,
        dataIndex: "sign_in_time",
        key: "sign_in_time",
        render: (text) => (
          <Text level={4}>
            {moment.utc(text).local().format("YYYY-MM-DD HH:mm:ss")}
          </Text>
        ),
      },
      {
        title: headerSignoutTime,
        dataIndex: "sign_out_time",
        key: "sign_out_time",
        render: (text) => {
          const date = moment.utc(text).local().format("YYYY-MM-DD HH:mm:ss");
          return (
            <Text level={4}>{date !== "Invalid date" ? date : noSignout}</Text>
          );
        },
      },
      {
        title: headerActions,
        key: "actions",
        render: (text, record) => (
          <React.Fragment>
            <Button
              type="primary"
              className="m-r-6"
              onClick={() => this.signout(record)}
              disabled={record.sign_out_time !== null}
            >
              <FormattedMessage
                id="VisitorLogPage.Detail.Button.Signout"
                defaultMessage="Abmeldezeit"
              ></FormattedMessage>
            </Button>
            <Button type="primary" onClick={() => this.showDrawer(record)}>
              <FormattedMessage
                id="VisitorLogPage.Table.Row.Details"
                defaultMessage="Details"
              ></FormattedMessage>
            </Button>
          </React.Fragment>
        ),
      },
    ];

    return (
      <div className="visitor-log-wrapper">
        <Row>
          <Col xs={24}>
            <Title level={1} className="page-title">
              <FormattedMessage
                id={visitorlogTitle}
                defaultMessage="Besucherprotokoll"
              ></FormattedMessage>
            </Title>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={14} xl={6}>
            <Card title={reportCardTitle} className="widgets">
              <Row>
                <Col xs={24}>
                  <RangePicker
                  size="large"
                  format="DD-MM-YYYY"
                  defaultValue={this.state.selectedDate}
                  disabledDate={this.disabledDate}
                  onChange={this.dateChange}
                  className="full-width"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Button
                  className="float-right m-t-12"
                  type="primary"
                  size="large"
                  onClick={this.export}
                  >
                    <FormattedMessage
                      id="VisitorLogPage.Button.CSV"
                      defaultMessage="Exportieren in CSV"
                    ></FormattedMessage>
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={10} xl={9} xxl={6}>
            <Card className="widgets">
              <Skeleton loading={this.props.isSuperAccount === null} avatar active>
                <Row gutter={[12, 0]} className="widget--content" align="middle" justify="space-around">
                  <Col xs={9}>
                    <div className="widget--number">
                      {this.state.currentVisitorCount}
                    </div>
                  </Col>
                  <Col xs={15}>
                    <div className="widget--text">
                      <FormattedMessage id={notCheckedOut}></FormattedMessage>
                      {/* {
                        this.props.isSuperAccount === true &&
                        <FormattedMessage
                          id="VisitorLogPage.SuperAccount.VisitorCount.NotCheckedOut"
                          defaultMessage="Anzahl Besucher aktuell im Gebäude"
                        ></FormattedMessage>
                      } */}
                    </div>
                  </Col>
                </Row>
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} md={10} xl={9} xxl={6}>
            <Card className="widgets">
              <Skeleton loading={this.props.isSuperAccount === null} avatar active>
                <Row gutter={[12, 0]} className="widget--content" align="middle" justify="space-around">
                  <Col xs={9}>
                    <div className="widget--number">
                      {this.state.totalVisitorCount}
                    </div>
                  </Col>
                  <Col xs={15}>
                    <div className="widget--text">
                      <FormattedMessage
                        id="VisitorLogPage.VisitorCount.CheckedOut"
                        defaultMessage="Gesamtanzahl registrierter Besucher"
                      ></FormattedMessage>
                    </div>
                  </Col>
                </Row>
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} md={10} xl={9} xxl={6}>
            <Card className={`widgets card-has-footer ${this.state.totalAlerts || this.state.alertsWidgetError > 0 ?
                                                      'card-has-emergency' : ''}`}
             actions={[
              <div className="widget-single-action" onClick={
                () => this.props.history.push('/dashboard/alerts')
              }><FormattedMessage
                  id="Widget.Alert.Action"
                  defaultMessage="Go to emergency page"
                ></FormattedMessage> <RightOutlined /></div>,
            ]}
            >
              <Skeleton loading={this.props.isSuperAccount === null} avatar active>
                <Row gutter={[12, 0]} className="widget--content" align="middle" justify="space-around">
                  { !this.state.alertsWidgetError &&
                  <>
                    <Col xs={9}>
                      <div className="widget--number">
                        {this.state.totalAlerts}
                      </div>
                    </Col>
                    <Col xs={15}>
                      <div className="widget--text">
                        <FormattedMessage
                          id="Widget.Alert.Text"
                          defaultMessage="Number of urgent service requests"
                        ></FormattedMessage>
                      </div>
                    </Col>
                  </>
                  }
                  { this.state.alertsWidgetError &&
                  <Col xs={24}>
                      <div className="widget--text--error">
                        <FormattedMessage
                          id="Widget.Alert.Error.Text"
                          defaultMessage="Not able to retrieve alerts, please try again later"
                        ></FormattedMessage>
                      </div>
                  </Col>
                  }
                </Row>
              </Skeleton>
            </Card>
          </Col>
        </Row>
        <Spin
          spinning={this.props.isSuperAccount === null}
          tip={loading}
          size="large"
          style={{height: "20vh"}}
        >
          <Row>
            <Col xs={24} md={12} xl={6}>
              <Input
                id="search"
                style={{width: "90%"}}
                placeholder={search}
                ref={this.searchInput}
                className="m-t-12"
                onChange={(e) => this.applyFilter(this.search(e.target.value))}
                suffix={<SearchOutlined />}
              />
              <Tooltip className="m-l-6" style={{display: 'inline-block'}}>
                  <InfoCircleOutlined
                    placement="topLeft"
                    title="Use the free text search to filter visitors using their name, e-mail address and visitor type classification."
                  />
                </Tooltip>
            </Col>
            <Col xs={24} md={12} xl={5}>
              <Dropdown
                className="m-t-12 p-t-4 m-l-12"
                overlay={this.createDropdown()}
              >
                <span
                  className="pointer inline-block"
                  onClick={(e) => e.preventDefault()}
                >
                  {filterBy} <DownOutlined />
                </span>
              </Dropdown>
              {this.state.selectedUserType && (
                <span className="m-l-6">
                  <Tag
                    closable
                    onClose={() => {
                      this.setState({
                        selectedUserType: null,
                      });
                      this.resetFilter("typeFilter");
                    }}
                    className="pointer"
                    color="geekblue"
                  >
                    {this.state.selectedUserType}
                  </Tag>
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Table
                className="table--white margin-32 v-margin"
                columns={columns}
                dataSource={this.state.filteredVisitorsList}
                bordered
                pagination={false}
              />
            </Col>
          </Row>
          {/* Datacharts display need to be decided. Probably for all accounts
          and not just for super accounts*/}
          {/* {
            this.props.isSuperAccount === true &&
            <Row>
              <Col xs={24}>
                <DataCharts />
              </Col>
            </Row>
          } */}
        </Spin>

        {/* USER MORE INFO DRAWER */}
        {this.state.selectedVisitor && (
          <UserInfoDrawer
            visible={this.state.showVisitorInfo}
            onClose={this.onClose}
            selectedVisitor={this.state.selectedVisitor}
            refetch={() => this.fetchList(this.state.selectedDate)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
    isSuperAccount: state.companyConfig.super_account,
  };
};

export default withRouter(connect(mapStateToProps)(VisitorLog));