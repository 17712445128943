import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Card,
  Col,
  Collapse,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import QuestionForm from "./forms/questionForm";
import http from "../../utils/http";
import { useSelector } from "react-redux";
import ModalWrapper from "../../components/Modal";
import { getIntl } from "../../utils/intl";
const { Title, Paragraph } = Typography;

export const PROTECTED_FIELDS = ['email', 'full_name']

function QuestionList(props) {
  const intl = getIntl();
  const companyId = useSelector((state) => state.companyConfig.company_id);
  const [questions, setQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});

  const questionCreationTitle = intl.formatMessage({
    id: "VisitorQuestionFlowPage.QuestionCreation.Title",
    defaultMessage: "Neue Frage erstellen",
  });

  const questionCreationText = intl.formatMessage({
    id: "VisitorQuestionFlowPage.QuestionCreation.Text",
    defaultMessage:
      'Nutzen Sie diese Funktion um Fragen zu erstellen, mit denen Sie Ihren Fragebogen ergänzen möchten. Sie können die Reihenfolge der erstellten Fragen Hilfe der Registerkarte "Fragen-Organisator" anpassen.',
  });

  const actionsText = intl.formatMessage({
    id: "Actions",
    defaultMessage: "Aktionen",
  });

  const deleteText = intl.formatMessage({
    id: "Delete",
    defaultMessage: "Löschen",
  });

  const editText = intl.formatMessage({
    id: "Edit",
    defaultMessage: "Bearbeiten",
  });

  const jaText = intl.formatMessage({
    id: "Yes",
    defaultMessage: "Ja",
  });

  const noText = intl.formatMessage({
    id: "No",
    defaultMessage: "Nein",
  });

  const requiredText = intl.formatMessage({
    id: "QuestionList.Required",
    defaultMessage: "Muss beantwortet werden",
  });

  const typeText = intl.formatMessage({
    id: "QuestionList.Type",
    defaultMessage: "Typ",
  });

  const questionText = intl.formatMessage({
    id: "QuestionList.Question",
    defaultMessage: "Frage",
  });

  const dropdownTypeText = intl.formatMessage({
    id: "QuestionType.Dropdown",
    defaultMessage: "Dropdown",
  });

  const freeTextTypeText = intl.formatMessage({
    id: "QuestionType.FreeText",
    defaultMessage: "Text",
  });

  const multipleSelectionTypeText = intl.formatMessage({
    id: "QuestionType.Multiselect",
    defaultMessage: "Auswahlmöglichkeiten",
  });

  const titleText = intl.formatMessage({
    id: "QuestionList.Title",
    defaultMessage: "Fragen erstellen und bearbeiten",
  });

  const QuestionTypeQuestions = {
    "Single Line Text": <Tag color="magenta">{freeTextTypeText}</Tag>,
    "Multi Line Text": <Tag color="orange"> Multiple Line Answer</Tag>,
    Dropdown: <Tag color="green">{dropdownTypeText}</Tag>,
    "Multiple Select": <Tag color="volcano">{multipleSelectionTypeText}</Tag>,
  };

  const IsRequired = ({ required }) =>
    required ? (
      <Tag color="red">{jaText}</Tag>
    ) : (
      <Tag color="geekblue">{noText}</Tag>
    );

  const fetchQuestions = async () => {
    try {
      const {
        data: { payload },
      } = await http.get(`/company/${companyId}/questions`);
      setQuestions(orderQuestions(payload));
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const orderQuestions = (toOrder) => {
    return Array.from(toOrder).sort((a, b) => {
      return a.question.toLowerCase().localeCompare(b.question.toLowerCase());
    });
  };

  useEffect(() => {
    const reorderedQuestions = orderQuestions(questions);
    const previousArray = questions.map((question) => question.id);
    const orderedArray = reorderedQuestions.map((question) => question.id);
    if (!_.isEqual(previousArray, orderedArray)) {
      setQuestions(reorderedQuestions);
    }
  }, [questions]);

  const deleteQuestion = async (question) => {
    const { id } = question;
    if (id) {
      const result = await http.delete(`/company/${companyId}/questions/${id}`);
      if (result.status >= 200 && result.status < 300) {
        await fetchQuestions();
      }
    }
  };

  const columns = [
    {
      title: questionText,
      dataIndex: "question",
      key: "question",
    },
    {
      title: typeText,
      dataIndex: "question_type",
      key: "question_type",
      render: (type) => QuestionTypeQuestions[type],
    },
    {
      title: requiredText,
      dataIndex: "required",
      key: "required",
      render: (required) => <IsRequired required={required} />,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
              setSelectedQuestion(record);
            }}
          >
            {editText}
          </Button>
          <Button type="ghost" danger onClick={() => deleteQuestion(record)} disabled={PROTECTED_FIELDS.indexOf(record?.field_name) >= 0}>
            {deleteText}
          </Button>
        </Space>
      ),
    },
  ];

  const QuestionList = () => {
    if (questions.length > 0) {
      return (
        <Table
          dataSource={questions}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id}
        />
      );
    }

    return "no data";
  };

  return (
    <div className="question-list__wrapper">
      <ModalWrapper
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedQuestion({});
        }}
        destroyOnClose={true}
        okButtonProps={{
          style: { display: "none" },
        }}
      >
        <div className="p-t-24 p-l-24 p-r-24 p-b-24">
          <QuestionForm
            questionToEdit={selectedQuestion}
            setQuestionToEdit={setSelectedQuestion}
            refreshQuestions={fetchQuestions}
            closeModal={() => setIsModalOpen(false)}
          />
        </div>
      </ModalWrapper>
      <Row align="bottom">
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Title level={1} className="question-list__title">
                {titleText}
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={10}>
          <Paragraph>{questionCreationText}</Paragraph>
          <Button
            size="large"
            block
            type="primary"
            onClick={() => setIsModalOpen(true)}
          >
            {questionCreationTitle}
          </Button>
        </Col>
      </Row>
      <Row gutter={12} className="p-t-12">
        <Col xs={24}>
          <QuestionList />
        </Col>
      </Row>
    </div>
  );
}

export default QuestionList;
