import React, { Component } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import http from "../../utils/http";
import { getIntl } from "../../utils/intl";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { displayNotification } from '../../components/Notification';

const { Title, Text, Paragraph } = Typography;

const MULTIPLE_SELECTION_TYPE = 4;

class UserInfoDrawer extends Component {
  constructor(props) {
    super(props);
    this.intl = getIntl();
    this.state = {
      selectedVisitor: this.props.selectedVisitor,
      questions: [],
      answers: [],
      loading: true,
      showModal: false,
      confirmLoading: false,
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    const previousUserId = prevProps.selectedVisitor.id;
    const previousTypeId = prevProps.selectedVisitor.visitor_type_id;
    const { visitor_type_id, id } = this.props.selectedVisitor;
    if (previousTypeId !== visitor_type_id) {
      this.fetchQuestions();
    }
    if (previousUserId !== id) {
      this.setState({
        selectedVisitor: this.props.selectedVisitor,
      });
      this.organizeAnswers();
    }
  }

  fetchQuestions = () => {
    const { company_id } = this.props.companyConfig;
    const { visitor_type_id } = this.props.selectedVisitor;
    http
      .get(`/company/${company_id}/visitor_types/${visitor_type_id}/questions`)
      .then((response) => {
        this.setState(
          {
            questions: response.data.payload,
          },
          () => this.organizeAnswers()
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading: false,
        });
      });
  };

  organizeAnswers = () => {
    const NoAnswer = this.intl.formatMessage(
      {
        id: "NoAnswer",
        defaultMessage: "Keine Antwort"
      }
    );
    const answeredQuestionsIds = this.props.selectedVisitor.answers.map(
      (answer) => answer.question_id
    );

    const numAnswerdQuestions = answeredQuestionsIds.length;
    const numQuestions = this.state.questions.length;
    const questionAnswers = [];
    const answers = this.props.selectedVisitor.answers;
    for (let i = 0; i < numAnswerdQuestions; i += 1) {
      const answerId = answers[i].question_id;
      for (let j = 0; j < numQuestions; j += 1) {
        const questionId = this.state.questions[j].id;
        //
        // @TODO
        //
        // REMOVE field_name === email clause after GDPR guidance
        //
        if ((answerId === questionId) && this.state.questions[j].field_name !== 'email') {
          let answer;
          if (answers[i].value instanceof Array) {
            if (answers[i].value.length > 0) {
              answer = answers[i].value[0];
            } else {
              answer = NoAnswer;
            }
          }

          if (this.state.questions[j].question_type_id === MULTIPLE_SELECTION_TYPE) {
            answer = answers[i].value.reduce((acc, value, index) => {
              if (value.checked) {
                acc.push((<Tag key={index}>{value.text}</Tag>));
              }
              return acc;
            }, []);
          }

          questionAnswers.push({
            key: answerId,
            question: this.state.questions[j].question,
            answer,
          });
        }
      }
    }
    this.setState({
      answers: questionAnswers,
      loading: false,
    });
  };

  showDeleteModal = () => {
    this.setState({
      showModal: true,
    });
  }
  
  annonymiseVisitor = async () => {
    const { company_id } = this.props.companyConfig;
    const { id, sign_in_time } = this.props.selectedVisitor;
    const successTitle = this.intl.formatMessage(
      {
        id: "Success",
        defaultMessage: "Erfolg!",
      }
    );
    const successMessage = this.intl.formatMessage(
      {
        id: "VisitorLogPage.Detail.Notification.Success.Body",
        defaultMessage: "Die Besucherdaten wurden erfolgreich gelöscht",
      }
    );
    const errorTitle = this.intl.formatMessage(
      {
        id: "Error",
        defaultMessage: "Etwas ist schief gelaufen!",
      }
    );
    const errorMessage = this.intl.formatMessage(
      {
        id: "VisitorLogPage.Detail.Notification.Error.Body",
        defaultMessage: "Die Besucherdaten konnten nicht gelöscht werden",
      }
    );
    try {
      this.setState({
        confirmLoading: true,
      });
      const result = await http.delete(`/company/${company_id}/visitors/${id}`);
      if (result.status === 204) {
        setTimeout(() => {
          this.setState({
            confirmLoading: false,
            showModal: false,
          }, () => {
            displayNotification('success', successTitle, successMessage);
            this.props.onClose(this.props.refetch);
          })
        }, 2000);
      }
    } catch (error) {
      this.setState({
        confirmLoading: false,
        showModal: false,
      }, () => {
        displayNotification('error', errorTitle, errorMessage);
      });
    }
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const headerQuestions = this.intl.formatMessage(
      {
        id: "VisitorLogPage.Detail.Table.Column.Questions",
        defaultMessage: "Fragen"
      }
    );
    const headerAnswers = this.intl.formatMessage(
      {
        id: "VisitorLogPage.Detail.Table.Column.Answers",
        defaultMessage: "Antworten"
      }
    );
    const columns = [
      {
        title: headerQuestions,
        dataIndex: "question",
        key: "question",
      },
      {
        title: headerAnswers,
        dataIndex: "answer",
        key: "answer",
      },
    ];
    const noSignout = this.intl.formatMessage(
      {
        id: "VisitorLogPage.Table.Column.Body.NoSignout",
        defaultMessage: "Kundenanfrage in Bearbeitung"
      }
    );

    /**
     * TODO:
     * `company_employee_email` columns needs to be conditionally shown
      based on the vertical 
     */
    return (
      <>
        <Modal
          title="Visitor Deletion"
          visible={this.state.showModal}
          onOk={this.annonymiseVisitor}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          cancelText={
            <FormattedMessage
              id="Cancel"
              defaultMessage="Abbrechen"
            ></FormattedMessage>
          }
          okText={
            <FormattedMessage
              id="Delete"
              defaultMessage="Löschen"
            ></FormattedMessage>
          }
          okButtonProps={{ danger: true }}
        >
          <p>
            <FormattedMessage
              id="VisitorLogPage.Detail.Delete.Disclaimer"
              defaultMessage="Diese Aktion wird die ausgewählten Besucherdaten löschen.
              Es werden keine Informationen mehr über den Besucher vorhanden sein.
              Ein anonymer Eintrag wird im Besucherprotokoll aus gesundheitlichen und statistischen Gründen erstellt."
            ></FormattedMessage>
          </p>
          <p>
            <FormattedMessage
              id="Proceed.Irreversible"
              defaultMessage="Diese Aktion kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten?"
            ></FormattedMessage>
          </p>
        </Modal>
        <Drawer
          width={640}
          placement="right"
          closable={false}
          onClose={this.props.onClose}
          visible={this.props.visible}
          className="vistor-log-drawer"
        >
          {this.props.selectedVisitor && (
            <div>
              <Row>
                <Col xs={24}>
                  <Title level={2}>
                    <FormattedMessage
                      id="VisitorLogPage.Detail.Header"
                      defaultMessage="Besucherinformationen"
                    ></FormattedMessage>
                  </Title>
                  <Divider />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col>
                  <Avatar
                    className="h-align margin-32 v-margin"
                    size={200}
                    src={this.props.selectedVisitor.image_url}
                    alt={`Avatar for visitor ${this.props.selectedVisitor.full_name}`}
                  />
                </Col>
              </Row>
              <Row className="margin-24 v-margin" gutter={24}>
                <Col xs={24} lg={12}>
                  <Paragraph>
                    <Text className="text-size-2" strong>
                      <FormattedMessage
                        id="VisitorLogPage.Detail.FullName"
                        defaultMessage="Vollständiger Name"
                      ></FormattedMessage>
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    {!this.state.selectedVisitor.pseudonymized && (
                      <Text className="text-size-2" type="secondary">
                        {this.props.selectedVisitor.full_name}
                      </Text>
                    )}
                    {this.state.selectedVisitor.pseudonymized && (
                      <Text className="text-size-2" type="secondary">
                        <FormattedMessage
                          id="VisitorLogPage.Anonymized.Reason"
                          defaultMessage="Die Besucherdaten wurden auf Ihre Anfrage hin gelöscht"
                        ></FormattedMessage>
                      </Text>
                    )}
                  </Paragraph>
                </Col>
                {/**
                 * @TODO
                 *
                 * REMOVED UNTIL GDPR GUIDANCE
                 * 
                 */}
                {/* <Col xs={24} lg={12}>
                  <Paragraph>
                    <Text className="text-size-2" strong>
                      <FormattedMessage
                        id="VisitorLogPage.Detail.Email"
                        defaultMessage="Email"
                      ></FormattedMessage>
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    {!this.state.selectedVisitor.pseudonymized && (
                      <Text className="text-size-2" type="secondary">
                        {this.props.selectedVisitor.email}
                      </Text>
                    )}
                    {this.state.selectedVisitor.pseudonymized && (
                      <Text className="text-size-2" type="secondary">
                        <FormattedMessage
                          id="VisitorLogPage.Anonymized.Reason"
                          defaultMessage="Die Besucherdaten wurden auf Ihre Anfrage hin gelöscht"
                        ></FormattedMessage>
                      </Text>
                    )}
                  </Paragraph>
                </Col> */}
              </Row>
              <Row className="margin-24 v-margin" gutter={24}>
                <Col xs={24} lg={12}>
                  <Paragraph>
                    <Text className="text-size-2" strong>
                      <FormattedMessage
                        id="VisitorLogPage.Detail.SignInTime"
                        defaultMessage="Anmeldezeit"
                      ></FormattedMessage>
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <Text className="text-size-2" type="secondary">
                      {moment
                        .utc(this.props.selectedVisitor.sign_in_time)
                        .local()
                        .format("DD-MM-YYYY HH:mm:ss")}
                    </Text>
                  </Paragraph>
                </Col>
                <Col xs={24} lg={12}>
                  <Paragraph>
                    <Text className="text-size-2" strong>
                      <FormattedMessage
                        id="VisitorLogPage.Detail.SignOutTime"
                        defaultMessage="Abmeldezeit"
                      ></FormattedMessage>
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <Text className="text-size-2" type="secondary">
                      {this.state.selectedVisitor.sign_out_time == null
                        ? noSignout
                        : moment
                            .utc(this.state.selectedVisitor.sign_out_time)
                            .local()
                            .format("DD-MM-YYYY HH:mm:ss")}
                    </Text>
                  </Paragraph>
                </Col>
              </Row>
              {!this.state.selectedVisitor.pseudonymized && (
                <Row className="margin-24 v-margin" gutter={24}>
                  <Col xs={24} lg={12}></Col>
                  <Col xs={24} lg={12}>
                    <Paragraph>
                      <Button
                        type="primary"
                        danger
                        onClick={this.showDeleteModal}
                      >
                        <FormattedMessage
                          id="Delete"
                          defaultMessage="Löschen"
                        ></FormattedMessage>
                      </Button>
                    </Paragraph>
                  </Col>
                </Row>
              )}
              {/* <Row className="margin-24 v-margin" gutter={24}>
              <Col xs={24} lg={12}>
                <Paragraph>
                  <Text className="text-size-2" strong>
                    <FormattedMessage id="VisitorLogPage.Detail.MeetingWith"
                      defaultMessage="Meeting mit">
                    </FormattedMessage>
                  </Text>
                </Paragraph>
                <Paragraph>
                  <Text className="text-size-2" type="secondary">
                    {this.props.selectedVisitor.company_employee_email}
                  </Text>
                </Paragraph>
              </Col>
            </Row> */}
              <Row>
                <Col span={24}>
                  <Table
                    dataSource={this.state.answers}
                    columns={columns}
                    loading={this.state.loading}
                    pagination={false}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};

export default connect(mapStateToProps)(UserInfoDrawer);
