import {
  DeploymentUnitOutlined,
  FolderOutlined,
  SettingOutlined,
  UserOutlined,
  LineChartOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { Alert, Col, Menu, Row, Space } from "antd";
import QRcode from "qrcode.react";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../../static/Logo";
import WelcomyIcon from "../../static/WelcomyIcon";
import { isAllSuperAccount } from "../../utils/envs";
import QrCodeLoadingModal from "../QrCode/QrCodeLoadingModal";
import AccountSelector from "./AccountSelector";
import QRCodePicker from "./QRCodePicker";
import { qrCodeExportModalOpen } from "../../redux/actions/qrCodeExport";
import ActionCards from "./action-cards/ActionCards";

const { SubMenu } = Menu;

class SideMenu extends Component {
  // submenu keys of first level
  rootSubmenuKeys = [
    "welcomytools",
    "office",
    "usermanagement",
    "welcomyHub",
    "analytics",
    "compliance",
    "workspaces",
    "myCompany"
  ];
  constructor(props) {
    super(props);
    this.routes = {
      workspaces: {
        2: "/workspaces",
      },
      welcomyHub: {
        20: "/",
        21: "/hub/config",
        22: "/sub-accounts",
      },
      welcomytools: {
        0: "/visitors",
        11: "/questions",
        12: "/subscriptions",
        13: "/digital-menu",
        14: "/print-menu",
      },
      office: {
        6: "/employees",
      },
      usermanagement: {
        200: "/user-management",
      },
      compliance: {
        10: "/impressum",
        101: "/data-protection",
      },
      analytics: {
        25: "/hub/analytics",
      },
      myCompany: {
        30: "/company-setup",
      },
    };
    this.short_link = props?.companyConfig?.short_link;
    this.qrRef = React.createRef();
    this.state = {
      defaultSelectedKey: props?.companyConfig?.super_account ? ['22'] : ['20'],
      selectedKey: [],
      openKeys: [],
      rootSubmenuKeys: [
        (
          "welcomytools",
          "office",
          "usermanagement",
          "welcomyHub",
          "analytics",
          "compliance",
          "analytics",
          "myCompany"
        ),
      ],
      tolltipCopyIsOpen: false,
      isLoadingQR: false,
    };

    this.copiedUrlMessage = (
      <FormattedMessage
        id="SideMenu.QuickAcions.Copied"
        defaultMessage="Kopiert!"
      />
    );

    if (props.companyConfig.sub_accounts.length > 0) {
      let initialList = [];
      this.accounts = props.companyConfig.sub_accounts.reduce(
        (acc, account) => {
          return {
            [account.id]: {
              path: account.path,
              id: account.id,
              vertical: account.vertical_id,
              name: account.name,
            },
            ...acc,
          };
        },
        {}
      );

      this.options = props.companyConfig.sub_accounts.reduce((acc, account) => {
        return acc.concat([
          {
            value: account.id,
            label: account.name,
          },
        ]);
      }, initialList);
    }
  }

  changeQrModalVisibility = () => {
    this.props.dispatch(qrCodeExportModalOpen(!this.props.modalOpen));
  }


  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1,
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  handleClick = (e) => {
    let { keyPath } = e;
    this.props.history.push(`/dashboard${this.routes[keyPath[1]][keyPath[0]]}`);
    if (keyPath[0] !== this.state.selectedKey[0]) {
      this.setState({
        selectedKey: [keyPath[0]],
        openKeys: [keyPath[1]],
      });
    }
  };

  render() {
    const { intl } = this.props;
    const verticalId = this.props.companyConfig.vertical_id;

    let visitorLogId = "SideMenu.SubMenu.VisitorLog";
    let flowSubMenuId = "SideMenu.SubMenu.Flow";
    let sideMenuTitle = "SideMenu.Restaurant";
    let productMenu = "SideMenu.SubMenu.DigitalMenu";

    if (verticalId && verticalId === 4) {
      visitorLogId = "SideMenu.Business.VisitorLog";
      flowSubMenuId = "SideMenu.Business.SubMenu.Flow";
      sideMenuTitle = "SideMenu.Business";
      productMenu = "SideMenu.Business.SubMenu.DigitalMenu";
    }

    return (
      <div className="sidemenu">
        <div className="logo-wrapper">
          <a href="/dashboard" className="icon">
            <WelcomyIcon />
          </a>
          <a href="/dashboard" className="logo--full">
            <Logo />
          </a>
        </div>
        <Row>
          <Col xs={0} lg={24}>
            <AccountSelector options={this.options} accounts={this.accounts}/>
          </Col>
        </Row>
        <Menu
          mode="vertical"
          onClick={this.handleClick}
          onOpenChange={this.onOpenChange}
          className="sidemenu__links"
          defaultSelectedKeys={this.state.defaultSelectedKey}
        >
          <SubMenu
            key="welcomyHub"
            title={
              <span>
                <DeploymentUnitOutlined />
                <span>
                  <FormattedMessage
                    id="SideMenu.SubMenu.WelcomyHub"
                    defaultMessage="Hub Verwaltung"
                  ></FormattedMessage>
                </span>
              </span>
            }
          >
            {isAllSuperAccount(this.props.companyConfig.super_account) && (
              <Menu.Item key="22">
                <FormattedMessage
                  id="SideMenu.SubMenu.Subaccounts"
                  defaultMessage="Übersicht"
                ></FormattedMessage>
              </Menu.Item>
            )}
            <Menu.Item key="20">
              <FormattedMessage
                id="SideMenu.WelcomyHub.Links"
                defaultMessage="Link Manager"
              ></FormattedMessage>
            </Menu.Item>
            <Menu.Item key="21">
              <FormattedMessage
                id="SideMenu.WelcomyHub.Layout"
                defaultMessage="Layout"
              ></FormattedMessage>
            </Menu.Item>
            <SubMenu
              key="compliance"
              title={
                <span>
                  <span>
                    <FormattedMessage
                      id="SideMenu.Compliance"
                      defaultMessage="Compliance"
                    ></FormattedMessage>
                  </span>
                </span>
              }
            >
              <Menu.Item key="10">
                <FormattedMessage
                  id="SideMenu.SubMenu.Impressum"
                  defaultMessage="Impressum"
                ></FormattedMessage>
              </Menu.Item>
              <Menu.Item key="101">
                <FormattedMessage
                  id="SideMenu.Configuration.Submenu.Datenschutz"
                  defaultMessage="Datenschutz"
                ></FormattedMessage>
              </Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu
            key="welcomytools"
            title={
              <span>
                <SettingOutlined />
                <span>
                  <FormattedMessage
                    id="SideMenu.WelcomyTools"
                    defaultMessage="Kundenanfragen"
                  ></FormattedMessage>
                </span>
              </span>
            }
          >
            <Menu.Item key="0">
              <FormattedMessage
                id={visitorLogId}
                defaultMessage="Besucherprotokoll"
              ></FormattedMessage>
            </Menu.Item>
            {(verticalId === 3 || verticalId === 4) && (
              <Menu.Item key="11">
                <FormattedMessage id={flowSubMenuId}></FormattedMessage>
              </Menu.Item>
            )}
            {(verticalId === 3 || verticalId === 4) && (
              <Menu.Item key="12">
                <FormattedMessage
                  id="SideMenu.Marketing.Newsletter"
                  defaultMessage="Newsletter Manager"
                ></FormattedMessage>
              </Menu.Item>
            )}
            {(verticalId === 3 || verticalId === 4) && (
              <Menu.Item key="13">
                <FormattedMessage id={productMenu}></FormattedMessage>
              </Menu.Item>
            )}
            {(verticalId === 3 || verticalId === 4) && (
              <Menu.Item key="14">
                <FormattedMessage
                  id="SideMenu.SubMenu.PrintMenu"
                  defaultMessage="Druckfunktion"
                ></FormattedMessage>
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu
            key="analytics"
            title={
              <span>
                <LineChartOutlined />
                <span>
                  <FormattedMessage
                    id="SideMenu.SubMenu.Analytics"
                    defaultMessage="Analytics"
                  ></FormattedMessage>
                </span>
              </span>
            }
          >
            <Menu.Item key="25">
              <FormattedMessage
                id="SideMenu.SubMenu.Analytics"
                defaultMessage="Analytics"
              ></FormattedMessage>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="usermanagement"
            title={
              <span>
                <UserOutlined />
                <span>
                  <FormattedMessage
                    id="SideMenu.SubMenu.UserManagement"
                    defaultMessage="User-Management"
                  ></FormattedMessage>
                </span>
              </span>
            }
          >
            <Menu.Item key="200">
              <FormattedMessage
                id="SideMenu.SubMenu.UserManagement"
                defaultMessage="User-Management"
              ></FormattedMessage>
            </Menu.Item>
          </SubMenu>
          {verticalId === 1 && (
            <SubMenu
              key="office"
              title={
                <span>
                  <FolderOutlined />
                  <span>
                    <FormattedMessage
                      id="SideMenu.Employees"
                      defaultMessage="Mitarbeiter"
                    ></FormattedMessage>
                  </span>
                </span>
              }
            >
              <Menu.Item key="6">
                <FormattedMessage
                  id="SideMenu.SubMenu.UploadEmployees"
                  defaultMessage="Mitarbeiter hochladen"
                ></FormattedMessage>
              </Menu.Item>
            </SubMenu>
          )}
          <SubMenu
            key="myCompany"
            title={
              <span>
                <BankOutlined />
                <span>
                  <FormattedMessage
                    id="SideMenu.MyCompany"
                    defaultMessage="Unternehmen"
                  ></FormattedMessage>
                </span>
              </span>
            }
          >
            <Menu.Item key="30">
              <FormattedMessage
                id="SideMenu.MyCompany.Configuration"
                defaultMessage="Konfigurator"
              ></FormattedMessage>
            </Menu.Item>
          </SubMenu>
          <div className="menu__actions">
            <QrCodeLoadingModal isLoadingQR={this.state.isLoadingQR} />
            <QRcode
              style={{ display: "none" }}
              size={400}
              id="qr-code-to-pdf"
              value={`https://${window.location.host}/public/welcome/form`}
            />
            <Space direction="vertical" style={{ width: "100%" }}>
              {this.state.tolltipCopyIsOpen && (
                <Alert
                  message={this.copiedUrlMessage}
                  type="success"
                  showIcon
                />
              )}
              <QRCodePicker
                changeQrModalVisibility={this.changeQrModalVisibility}
              />
              <ActionCards />
            </Space>
          </div>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
    modalOpen: state.qrCodeExportConfig.modalOpen,
  };
};

export default withRouter(connect(mapStateToProps)(injectIntl(SideMenu)));
