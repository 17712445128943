import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { getIntl } from '../../utils/intl';
import DecisionFlowForm from './forms/DecisionFlowForm';

const { Title, Paragraph } = Typography;

function DecisionFlowCard(props) {
  const intl = getIntl();
  const title = intl.formatMessage(
    {
      id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Title",
      defaultMessage: "Willkommensfrage"
    }
  );
  const disclaimer = intl.formatMessage(
    {
      id: "VisitorQuestionFlowPage.WelcomeQuestion.Card.Disclaimer",
      defaultMessage: "Dies ist die erste Frage in Ihrem Fragebogen. Die Frage beinflusst die Logik Ihres Fragebogens."
    }
  );
  return (
    <Card className="question-flow-sider">
      <Row key="a" className="p-t-24 p-b-24 p-l-24 p-r-24">
        <Col span={24}>
          <Title level={3} style={{ color: "#4c566a" }}>
            {title}
          </Title>
          <Paragraph>
            {disclaimer}
          </Paragraph>
          <DecisionFlowForm />
        </Col>
      </Row>
    </Card>
  );
}

export default DecisionFlowCard;