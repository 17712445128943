import React, { forwardRef } from "react";
import { Input, Button, Form, Row, Col } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import { getIntl } from "../../../utils/intl";

const visitorTypeForm = forwardRef(({ form, onSubmit, ...props }, ref) => {
    const intl = getIntl();
    const visitorTypePlaceholder = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.VisitorTypeForm.VisitorTypeName.Placeholder",
        defaultMessage: "Kundentyp eingeben"
      }
    );
  return (
    <Form layout="inline" onFinish={onSubmit} autoComplete="off">
      <Row gutter={[0, 12]}>
        <Col lg={24} xl={20}>
          <Form.Item name="visitorType"
            rules={[
              {
                required: true,
                message: "Please enter a new user type!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input
              name="visitor-type"
              autoComplete="off"
              prefix={
                <UserOutlined />
              }
              placeholder={visitorTypePlaceholder}
            />
          </Form.Item>
        </Col>
        <Col lg={24} xl={4}>
          <Button
            type="primary"
            htmlType="submit"
            sytle={{
              width: "25%",
            }}
          >
            <FormattedMessage id="VisitorQuestionFlowPage.VisitorTypeForm.Button.Add"
              defaultMessage="Hinzufügen">
            </FormattedMessage>
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default visitorTypeForm;
