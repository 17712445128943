import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  List,
  Tabs,
  Tag,
  Space,
} from "antd";
import { RightOutlined } from '@ant-design/icons';
import QueueAnim from "rc-queue-anim";

import Image from "../../static/fill_forms.svg";
import QuestionForm from "./forms/questionForm";
import FlowOrder from "./FlowOrder";
import http, { getErrorMsg } from "../../utils/http";
import { getIntl } from "../../utils/intl";
import { displayNotification } from "../../components/Notification";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import VisitorTypeCard from "./VisitorTypeCard";
import DecisionFlowCard from "./DecisionFlowCard";
import QuestionList from "./QuestionList";

const { Title, Paragraph, Text } = Typography;

const { TabPane } = Tabs;

class VisitorQuestionsFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: null,
      questions: [],
      typeOfVisitors: [],
      flow: {},
      created_types: [],
    };
    this.companyConfig = this.props.companyConfig;
    this.visitorRef = React.createRef();
    this.fetchUserTypes();

    this.visitorTypeSectionRef = React.createRef()  
  }

  openCreationForm = (formType) => {
    this.setState({
      openForm: formType,
    });
  };

  fetchUserTypes = async () => {
    const { company_id } = this.companyConfig;
    if (company_id) {
      try {
        const response = await http.get(`company/${this.companyConfig.company_id}/visitor_types`);
        const { payload } = response.data;
        this.setState({ created_types: payload });
      } catch (error) {
        console.log(error);
        const intl = getIntl();
        const title = intl.formatMessage(
          {
            id: "VisitorQuestionFlowPage.Notification.VisitorTypeFetchError.Title",
            defaultMessage: "Besuchertypen konnten nicht abgerufen werden"
          }
        );
        displayNotification("error", title, getErrorMsg(error, intl));
      }
    }
  };

  animationWrapper = (children) => {
    return (
      <QueueAnim delay={0} interval={150} duration={100} className="fade-in">
        {children}
      </QueueAnim>
    );
  };

  createUserType = (values) => {
    let visitorType = values.visitorType.trim();
    visitorType = visitorType.charAt(0).toUpperCase() + visitorType.slice(1);

    const visitorsLength = this.state.typeOfVisitors.length;
    if (
      (!visitorsLength ||
        this.state.typeOfVisitors.indexOf(visitorType) === -1) &&
      visitorsLength < 6
    ) {
      this.setState((state) => {
        return {
          typeOfVisitors: [...state.typeOfVisitors, visitorType],
        };
      });
    }

    if (visitorsLength === 6) {
      const intl = getIntl();
      const title = intl.formatMessage(
        {
          id: "VisitorQuestionFlowPage.Notification.VisitorTypeMaxError.Title",
          defaultMessage: "Es kann kein weiterer Besuchertyp hinzugefügt werden"
        }
      );
      const body = intl.formatMessage(
        {
          id: "VisitorQuestionFlowPage.Notification.VisitorTypeMaxError.Body",
          defaultMessage: "Leider können Sie nicht mehr als 6 Besuchertypen hinzufügen"
        }
      );
      displayNotification("error", title, body);
    }
  };

  removeVisitorType = (item) => {
    const visitorIndex = this.state.typeOfVisitors.indexOf(item);
    const Aux = Array.from(this.state.typeOfVisitors);
    Aux.splice(visitorIndex, 1);
    this.setState({
      typeOfVisitors: Aux,
    });
  };

  submitUserTypes = async () => {
    const { company_id } = this.companyConfig;
    const toSubmit = this.state.typeOfVisitors.map((type) => {
      return { type };
    });

    try {
      const response = await http.post(`company/${company_id}/visitor_types`, {
        visitor_types: toSubmit,
      });
      if (response.status === 200) {
        this.fetchUserTypes();
        this.setState({ typeOfVisitors: [] });
        const intl = getIntl();
        const title = intl.formatMessage(
          {
            id: "VisitorQuestionFlowPage.Notification.VisitorTypeCreateSuccess.Title",
            defaultMessage: "Erfolg!"
          }
        );
        const body = intl.formatMessage(
          {
            id: "VisitorQuestionFlowPage.Notification.VisitorTypeCreateSuccess.Body",
            defaultMessage: "Der Besuchertyp wurde erstellt"
          }
        );
        displayNotification("success", title, body);
      }
    } catch (error) {
      console.log(error);
      const intl = getIntl();
      const title = intl.formatMessage(
        {
          id: "VisitorQuestionFlowPage.Notification.VisitorTypeCreateError.Title",
          defaultMessage: "Besuchertyp konnte nicht erstellt werden"
        }
      );
      displayNotification("error", title, getErrorMsg(error, intl));
    }
  };

  deleteUserType = async (visitor_type_id) => {
    const { company_id } = this.companyConfig;

    try {
      const response = await http.delete(`company/${company_id}/visitor_types/${visitor_type_id}`);
      if (response.status === 204) {
        this.fetchUserTypes();
        this.setState({ typeOfVisitors: [] });
        const intl = getIntl();
        const title = intl.formatMessage(
          {
            id: "VisitorQuestionFlowPage.Notification.VisitorTypeDeleteSuccess.Title",
            defaultMessage: "Erfolg!"
          }
        );
        const body = intl.formatMessage(
          {
            id: "VisitorQuestionFlowPage.Notification.VisitorTypeDeleteSuccess.Body",
            defaultMessage: "Besuchertyp wurde erfolgreich gelöscht"
          }
        );
        displayNotification("success", title, body);
      }
    } catch (error) {
      console.log(error);
      const intl = getIntl();
      const title = intl.formatMessage(
        {
          id: "VisitorQuestionFlowPage.Notification.VisitorTypeDeleteError.Title",
          defaultMessage: "Besuchertyp konnte nicht gelöscht werden"
        }
      );
      displayNotification("error", title, getErrorMsg(error, intl, false, true));
    }
  };

  renderProcessStep = () => {
    if (this.state.openForm === "create-visitor-type") {
      return (
        <VisitorTypeCard
          created_types={this.state.created_types}
          typeOfVisitors={this.state.typeOfVisitors}
          removeVisitorType={this.removeVisitorType}
          submitUserTypes={this.submitUserTypes}
          createUserType={this.createUserType}
          deleteUserType={this.deleteUserType}
          visitorRef={this.visitorRef}
        />
      );
    } else if (this.state.openForm === "create-question") {
      return (
        <QuestionForm
          wrappedComponentRef={this.visitorRef}
          onSubmit={this.createUserType}
        />
      );
    } else if (this.state.openForm === 'create-decision-flow') {
      return (
        <DecisionFlowCard />
      )
    }

    return null;
  };

  render() {
    const intl = getIntl();
    const questionsTabTitle = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.Tab.QuestionSettings.Title",
      }
    );
    const questionOrganiserTitle = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.Tab.QuestionOrder.Title",
      }
    );
    const questionCrationTabTitle = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.Tab.QuestionCreation.Title",
      }
    );

    /**
     * Welcome Question Texts
     */

    const welcomeQuestionTitle = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.WelcomeQuestion.Title",
        defaultMessage: "Willkommensfrage"
      }
    );

    const welcomeQuestionText = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.WelcomeQuestion.Text",
        defaultMessage: "Erstellen Sie zuerst Ihre Begrüßungsfrage. Die Frage entscheidet darüber, welche Logik Ihr weiterer Fragebogen verfolgt."
      }
    );

    const welcomeQuestionButton = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.WelcomeQuestion.Button",
        defaultMessage: "Menüpunkt \"Willkommensfrage\" öffnen"
      }
    );

    /**
     * Visitor Types Texts
     */

     const visitorTypesTitle = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.VisitorTypes.Title",
        defaultMessage: "Kundentypen"
      }
    );

    const visitorTypesTextFirst = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.VisitorTypes.Text.One",
        defaultMessage: "In Ihrer Willkommensfrage können Sie den Nutzern mehrere Optionen anbieten, die für die weitere Qualifizierung wichtig ist. Wir nennen dies \"Besuchertypen\"."
      }
    );

    const visitorTypesTextSecond = intl.formatMessage(
      {
        id: "VisitorQuestionFlowPage.VisitorTypes.Text.Two",
        defaultMessage: "Um dies besser zu erklären, betrachten wir ein Szenario, in dem Ihre Willkommensfrage lautet: \"Was ist der Grund für Ihre Kontaktaufnahme?\". Einige sinnvolle Optionen (Besuchertypen) für die Antworten könnten sein: \"Ich möchte über ein Geschäftsangebot sprechen\", \"Ich möchte mich um eine Stelle bewerben\" und \"Ich möchte mit einem Vertriebsmitarbeiter sprechen\"."
      }
    );

    const Tab1 = () => (
      <div className="tab-title">
        <Space>
          <span>
            {questionsTabTitle}
          </span>
          <RightOutlined size="small" />
        </Space>
      </div>
    );

    const Tab2 = () => (
      <div className="tab-title">
        <Space>
          <span>
            {questionCrationTabTitle}
          </span>
          <RightOutlined size="small" />
        </Space>
      </div>
    );

    const Tab3 = () => (
      <div className="tab-title">
        <Space>
          <span>
            {questionOrganiserTitle}
          </span>
          <RightOutlined size="small" />
        </Space>
      </div>
    );

    return (
      <div>
        <Tabs type="card">
          <TabPane tab={<Tab1 />} key="1">
            <Row type="flex" align="top">
              <Col xl={12} md={24}>
                <Title
                  style={{ color: "#4c566a", margin: "0px 16px 16px 0px" }}
                >
                  <FormattedMessage id="VisitorQuestionFlowPage.Title"></FormattedMessage>
                </Title>
                <Paragraph>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.Description"
                    defaultMessage=""
                    values={{ visitor_type: <Text code>visitor type</Text> }}
                  ></FormattedMessage>
                </Paragraph>
                <Title level={3} style={{ color: "#4c566a" }}>
                  {welcomeQuestionTitle}
                </Title>
                <Paragraph>
                  {welcomeQuestionText}
                </Paragraph>
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() =>
                        this.openCreationForm("create-decision-flow")
                      }
                    >
                      {welcomeQuestionButton}
                    </Button>
                  </Col>
                </Row>
                <Row className="p-t-24">
                  <Col>
                    <Title level={3} style={{ color: "#4c566a" }}>
                      {visitorTypesTitle}
                    </Title>
                    <Paragraph>
                      {visitorTypesTextFirst}
                    </Paragraph>
                    <Paragraph>
                      {visitorTypesTextSecond}
                    </Paragraph>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={() =>
                        this.openCreationForm("create-visitor-type")
                      }
                    >
                      <FormattedMessage
                        id="VisitorQuestionFlowPage.Button.CreateVisitorType"
                        defaultMessage="Besuchertyp erstellen"
                      ></FormattedMessage>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xl={11} md={24}>
                <div
                  className="m-l-24 m-r-24 m-t-24 m-b-6 right-col-wrapper"
                >
                  {this.renderProcessStep()}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={<Tab2 />} key="2">
            <QuestionList />
          </TabPane>
          <TabPane tab={<Tab3 />} key="3">
            <FlowOrder />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};

export default connect(mapStateToProps)(VisitorQuestionsFlow);
