import React, { useEffect, useState, useRef } from 'react';

import { Chart, registerables } from 'chart.js';
import http from '../../utils/http';
import { getIntl } from "../../utils/intl";
import moment from 'moment';
import { useSelector } from "react-redux";
import {
    Row,
    Col,
    Typography,
    DatePicker,
    Avatar,
    Table,
    Button,
    Input,
    Dropdown,
    Menu,
    Tag,
    Tooltip,
    Card,
    Divider
  } from "antd";

Chart.register(...registerables);

function DataCharts(props) {
    const companyId = useSelector(state => state.companyConfig.company_id);

    const monthRef = useRef(null);
    const weekRef = useRef(null);
    const distributionRef = useRef(null);
    const [monthVisitors, setMonthVisitors] = useState(null);
    const [weekVisitors, setWeekVisitors] = useState(null);
    const [visitorsDistribution, setVisitorsDistribution] = useState(null);

    const intl = getIntl();

    const yAxis = intl.formatMessage({
        id: "DataCharts.Axis.NumberVisitors",
        defaultMessage: "Anzahl der Besucher",
    });

    const sevenDaysTitle = intl.formatMessage({
        id: "DataCharts.Card.Title.Days.Seven",
        defaultMessage: "Besucher der letzten 7 Tage",
    });

    const thirtyDaysTitle = intl.formatMessage({
        id: "DataCharts.Card.Title.Days.Thirty",
        defaultMessage: "Besucher der letzten 30 Tage",
    });

    const visitorTypesTitle = intl.formatMessage({
        id: "DataCharts.Card.Title.VisitorTypes.Thirty",
        defaultMessage: "Aufteilung der Besuchertypen der letzten 30 Tage",
    });

    const getRandomColor = () => {
        return "hsl(" + ((270 - 90) * Math.random() + 90) + ',' +
             (25 + 50 * Math.random()) + '%,' + 
             (70 + 10 * Math.random()) + '%)'
    }

    const mountLineChart = (reference, ref) => {
        if (reference) {
            const data = {
            labels: reference.map(entries => entries[0]),
            datasets: [
                {
                label: "Check-ins",
                data: reference.map(entries => entries[1]),
                borderColor: '#5e5bf8',
                fill: false,
                cubicInterpolationMode: "monotone",
                tension: 0.4,
                },
            ],
        };

        const config = {
            type: "line",
            data: data,
            options: {
                responsive: true,
                plugins: {},
                interaction: {
                intersect: false,
                },
                scales: {
                x: {
                    display: false,
                    title: {
                    display: true,
                    },
                },
                y: {
                    display: true,
                    title: {
                    display: true,
                    text: yAxis,
                    },
                    suggestedMin: 0,
                    suggestedMax: 10,
                },
                },
            },
            };

            const chart = new Chart(ref.current, config)
        }
    }

    const mountPieChart = (reference, ref) => {
        if (reference) {
            const data = {
                labels: reference.map(entries => entries[0]),
                datasets: [
                    {
                    label: "Distribution of Visitor Types",
                    data: reference.map(entries => entries[1]),
                    backgroundColor: reference.map(entries => getRandomColor(),)
                    },
                ],
            };

            const config = {
                type: "pie",
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                        }
                    }
                }
            };

            const chart = new Chart(ref.current, config)
        }
    }


    useEffect(() => {
        (async function() {
            try {
                const response = await http.get(`/company/${companyId}/metrics/visitors`,
                {
                    params: {
                        reference_date: moment().format('YYYY-MM-DD'),
                        time_window: 'month',
                    }
                }
                );
                setMonthVisitors(response.data.payload);
            } catch (error) {
                console.error(error)
            }

            try {
                const response = await http.get(`/company/${companyId}/metrics/visitors`,
                {
                    params: {
                        reference_date: moment().format('YYYY-MM-DD'),
                        time_window: 'week',
                    }
                }
                );
                setWeekVisitors(response.data.payload);
            } catch (error) {
                console.error(error)
            }

            try {
                const response = await http.get(`/company/${companyId}/metrics/visitors`,
                {
                    params: {
                        reference_date: moment().format('YYYY-MM-DD'),
                        chart_type: 'visitor_types',
                    }
                }
                );
                setVisitorsDistribution(response.data.payload);
            } catch (error) {
                console.error(error)
            }
        })();
    }, []);

    useEffect(() => {
        mountLineChart(monthVisitors, monthRef);
        mountLineChart(weekVisitors, weekRef);
        mountPieChart(visitorsDistribution, distributionRef);
    }, [visitorsDistribution]);

    return (
        <div className="c-datacharts">
            <Row gutter={16}>
                <Col xs={24} md={12} lg={8}>
                    <Card title={sevenDaysTitle} bordered={false} className="c-datacharts__card">
                        <div className="c-datacharts__canvas__wrapper">
                            <canvas ref={weekRef}/>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Card title={thirtyDaysTitle} bordered={false} className="c-datacharts__card">
                        <div className="c-datacharts__canvas__wrapper">
                            <canvas ref={monthRef}/>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Card title={visitorTypesTitle} bordered={false} className="c-datacharts__card">
                        <div className="c-datacharts__canvas__wrapper--pie">
                            <canvas ref={distributionRef}/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default DataCharts;