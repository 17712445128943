import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Button,
  Select,
  Typography,
  Tag,
  Form,
  Row,
  Col,
  Checkbox,
  Card,
} from "antd";
import { FormOutlined, CloseCircleOutlined } from "@ant-design/icons";

import http, { getErrorMsg } from "../../../utils/http";
import { getIntl } from "../../../utils/intl";
import { displayNotification } from "../../../components/Notification";
import { FormattedMessage } from "react-intl";
import { PROTECTED_FIELDS } from "../QuestionList";

const { Option } = Select;
const { Title, Text } = Typography;

const QuestionForm = ({
  onSubmit,
  questionToEdit = {},
  setQuestionToEdit = () => null,
  refreshQuestions = () => null,
  closeModal = () => null,
  ...props
}) => {
  const rState = useSelector((state) => state);

  const [noOptError, noOptErrorSetter] = useState(null);
  const [type, typeSetter] = useState(questionToEdit.question_type_id || "");
  const [optionCache, optionCacheSetter] = useState("");
  const [optionArray, optionArraySetter] = useState([]);
  const [form] = Form.useForm();
  const intl = getIntl();
  const questionTypesPlaceholder = intl.formatMessage({
    id: "VisitorQuestionFlowPage.QuestionsForm.QuestionTypeName.Placeholder",
    defaultMessage: "Wähle eine Option",
  });
  const questionTextPlaceholder = intl.formatMessage({
    id: "VisitorQuestionFlowPage.QuestionsForm.QuestionText.Placeholder",
    defaultMessage: "Ihre Frage",
  });

  useEffect(() => {
    console.log(questionToEdit);
      if (questionToEdit) {
        // console.log(form.getFieldsValue());
        form.setFieldsValue({
          "question-type-selector": {
            value: questionToEdit.question_type_id,
            label: questionTypes[questionToEdit.question_type_id],
          },
          "create-question-text-input": questionToEdit.question,
          "mandatory": questionToEdit.required,
        });
        if (questionToEdit?.options?.length > 0) {
          console.log('herlo');
          optionArraySetter([...questionToEdit.options]);
        } 
    // console.log(form.getFieldsValue());
      }
  }, [questionToEdit]);

  const updateQuestion = async (toBeSaved) => {
    // DO HTTP CALL
    try {
      const response = await http.post(
        `company/${rState.companyConfig.company_id}/questions/${questionToEdit.id}`,
        {
          ...toBeSaved,
        }
      );
      const intl = getIntl();
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Title",
        defaultMessage: "Erfolg",
      });
      const body = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Body",
        defaultMessage: "Ihre Frage wurde gespeichert!",
      });
      displayNotification("success", title, body);
      // delete current values in input
      form.resetFields([
        "create-option-text-input",
        "create-question-text-input",
        "mandatory",
      ]);
      optionCacheSetter("");
      setQuestionToEdit({});
      closeModal();
      form.resetFields();
      await refreshQuestions();
    } catch (error) {
      console.log(error);
      const intl = getIntl();
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveError.Title",
        defaultMessage: "Frage konnte nicht gespeichert werden",
      });
      displayNotification("error", title, getErrorMsg(error, intl, false, true));
    }
  };

  const validate = (values) => {
    console.log(values);
    const questionType = parseInt(values["question-type-selector"].value);
    const questionText = values["create-question-text-input"];
    const mandatory = values["mandatory"];
    // ALL MULTIPLE OPTIONS TYPE OF QUESTIONS SHOULD CONTAIN
    // AT LEAST ONE OPTION TO BE SELECTED
    if (
      (questionType === 3 || questionType === 4) &&
      optionArray.length === 0
    ) {
      noOptErrorSetter(
        "This type of question requires at least one option to be set!"
      );
    } else {
      console.log('else');
      const finalQuestion =
        questionType === 3 || questionType === 4
          ? {
              question_type_id: questionType,
              required: mandatory,
              question: questionText,
              options: optionArray,
            }
          : {
              question_type_id: questionType,
              question: questionText,
              required: mandatory,
            };

      console.log(finalQuestion);
      if (questionToEdit?.id?.length > 0) {
        console.log('editing');
        // We are editing a question
        updateQuestion({ id: questionToEdit.id, ...finalQuestion });
        return;
      }
      save_question(finalQuestion);
    }
  };

  const save_question = async (toBeSaved) => {
    // DO HTTP CALL
    try {
      const response = await http.post(
        `company/${rState.companyConfig.company_id}/questions`,
        {
          questions: [toBeSaved],
        }
      );
      const intl = getIntl();
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Title",
        defaultMessage: "Erfolg",
      });
      const body = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveSuccess.Body",
        defaultMessage: "Ihre Frage wurde gespeichert!",
      });
      displayNotification("success", title, body);
      // delete current values in input
      form.resetFields([
        "create-option-text-input",
        "create-question-text-input",
        "mandatory",
      ]);
      optionCacheSetter("");
      closeModal();
      form.resetFields();
      await refreshQuestions();
    } catch (error) {
      console.log(error);
      const intl = getIntl();
      const title = intl.formatMessage({
        id: "VisitorQuestionFlowPage.Notification.QuestionSaveError.Title",
        defaultMessage: "Frage konnte nicht gespeichert werden",
      });
      displayNotification("error", title, getErrorMsg(error, intl, false, true));
    }
  };

  const questionTypes = {
    1: "Free text",
    3: "Dropdown",
    4: "Multiple select",
    // 4: "Require signature",
  };

  const renderQuestionTypes = () => {
    const typeIndexes = Object.keys(questionTypes);
    return typeIndexes.map((type) => {
      return (
        <Option value={type} key={type}>
          {questionTypes[type]}
        </Option>
      );
    });
  };

  const handleChange = ({ value }) => {
    typeSetter(parseInt(value));
    form.resetFields([
      "create-option-text-input",
      "create-question-text-input",
    ]);
    optionCacheSetter("");
  };

  const onChangeOption = (e) => {
    optionCacheSetter(e.target.value);
  };

  const addOption = () => {
    optionArraySetter([...optionArray, optionCache]);
    form.resetFields(["create-option-text-input"]);
    optionCacheSetter("");
  };

  const removeOption = (index) => {
    optionArray.splice(index, 1);
    optionArraySetter([...optionArray]);
  };

  const createQuestionWithOptions = (questionType) => {
    const intl = getIntl();
    const optionsPlaceholder = intl.formatMessage({
      id: "VisitorQuestionFlowPage.QuestionsForm.OptionText.Placeholder",
      defaultMessage: "Optionsfeld",
    });

    const optionError = intl.formatMessage({
      id: "VisitorQuestionFlowPage.QuestionsForm.OptionError",
      defaultMessage: "Bitte geben Sie eine Auswahlmöglichkeit ein",
    });

    const questionError = intl.formatMessage({
      id: "VisitorQuestionFlowPage.QuestionsForm.QuestionError",
      defaultMessage: "Bitte geben Sie eine Fragestellung ein",
    });

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <Form.Item
              name="create-question-text-input"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: questionError,
                  whitespace: true,
                  type: "string",
                },
              ]}
            >
              <Input
                prefix={<FormOutlined />}
                placeholder={questionTextPlaceholder}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              name="create-option-text-input"
              rules={[
                {
                  message: optionError,
                  whitespace: true,
                  type: "string",
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={optionsPlaceholder}
                onChange={onChangeOption}
                value={optionCache}
                style={{
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button
              onClick={addOption}
              disabled={optionCache.trim().length > 0 ? false : true}
              type="primary"
              htmlType="button"
            >
              <FormattedMessage
                id="VisitorQuestionFlowPage.QuestionsForm.Button.AddOption"
                defaultMessage="Hinzufügen"
              ></FormattedMessage>
            </Button>
          </Col>
        </Row>
        <div>
          {optionArray.length > 0 && (
            <Title level={4} className="m-t-12">
              <FormattedMessage
                id="VisitorQuestionFlowPage.QuestionsForm.OptionsTitle"
                defaultMessage="Ihre bereits hinzugefügten Auswahlmöglichkeiten"
              ></FormattedMessage>
            </Title>
          )}
          {optionArray.map((option, index) => (
            <Tag
              key={`${option}-${index}`}
              closable
              className="m-b-24"
              onClose={() => removeOption(index)}
            >
              {option}
            </Tag>
          ))}
        </div>
        <Form.Item
          name="mandatory"
          valuePropName="checked"
        >
          <Checkbox checked={false}>
            <FormattedMessage
              id="VisitorQuestionFlowPage.QuestionsForm.MandatoryCheckbox.Text"
              defaultMessage="Frage muss beantwortet werden"
            ></FormattedMessage>
          </Checkbox>
        </Form.Item>
      </React.Fragment>
    );
  };

  const renderInput = () => {
    const questionError = intl.formatMessage({
      id: "VisitorQuestionFlowPage.QuestionsForm.QuestionError",
      defaultMessage: "Bitte geben Sie eine Fragestellung ein",
    });
    if (type === 1) {
      return (
        <>
          <Form.Item
            name="create-question-text-input"
            hasFeedback
            rules={[
              {
                required: true,
                message: questionError,
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input
              prefix={<FormOutlined />}
              placeholder={questionTextPlaceholder}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="mandatory"
            valuePropName="checked"
          >
            <Checkbox checked={false}>
              <FormattedMessage
                id="VisitorQuestionFlowPage.QuestionsForm.MandatoryCheckbox.Text"
                defaultMessage="Frage muss beantwortet werden"
              ></FormattedMessage>
            </Checkbox>
          </Form.Item>
        </>
      );
    } else if (type === 3 || type === 4) {
      return createQuestionWithOptions(type);
    }

    return null;
  };
  
  return (
    <Card className="question-flow-sider">
      <Row className="p-t-24 p-b-24 p-l-24 p-r-24">
        <Col span={24}>
          <Form style={{ width: "100%" }} form={form} onFinish={validate}>
            {/* SELECT A TYPE OF QUESTION */}
            <Row>
              <Col span={24}>
                <Title level={3} style={{ color: "#4c566a" }}>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.QuestionsForm.Title"
                    defaultMessage="Eine Frage erstellen"
                  ></FormattedMessage>
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title level={4} style={{ color: "#4c566a" }}>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.QuestionsForm.QuestionType.Title"
                    defaultMessage="Fragentyp"
                  ></FormattedMessage>
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.QuestionsForm.Body1"
                    defaultMessage="Wählen Sie hier zwischen einem der drei Fragenarten, mit denen Sie alle notwendigen Antworten Ihrer Besucher erhalten können."
                  ></FormattedMessage>
                </Text>
              </Col>
            </Row>
            <Row className="p-b-12">
              <Col span={24}>
                <Form.Item
                  className="p-t-12"
                  name="question-type-selector"
                  rules={[
                    {
                      required: true,
                      message: "Please select a question type!",
                    },
                  ]}
                >
                  <Select
                    placeholder={questionTypesPlaceholder}
                    style={{ width: 200 }}
                    onChange={handleChange}
                    disabled={PROTECTED_FIELDS.indexOf(questionToEdit?.field_name) >= 0}
                    labelInValue
                  >
                    {renderQuestionTypes()}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Title level={4} style={{ color: "#4c566a" }}>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.QuestionsForm.CreateQuestion.Title"
                    defaultMessage="Ihre Fragestellung"
                  ></FormattedMessage>
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text style={{ display: "inline-block", marginBottom: "12px" }}>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.QuestionsForm.Body2"
                    defaultMessage="Fügen Sie hier Ihre Frage und möglich Optionen ein. Nachdem Sie Ihre Frage bestätigt haben, können Sie diese im Menüpunkt {line_break}{flow_order} im Fragen Manager den Besuchertypen zuordnen."
                    values={{
                      flow_order: <Text code>Question Organiser</Text>,
                      line_break: <br />,
                    }}
                  ></FormattedMessage>
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {renderInput()}
                {type !== null && (
                  <>
                    {noOptError && (
                      <div className="text-error m-b-6">
                        <CloseCircleOutlined className="m-r-6" />
                        {noOptError}
                      </div>
                    )}
                    <Button
                      htmlType="submit"
                      type="primary"
                      htmlType="submit"
                      sytle={{
                        width: "25%",
                      }}
                    >
                      <FormattedMessage
                        id="VisitorQuestionFlowPage.QuestionsForm.Button.Save"
                        defaultMessage="Bestätigen"
                      ></FormattedMessage>
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default QuestionForm;
