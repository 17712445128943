import React from "react";
import { Transfer, Button, Table, Tag, Typography } from "antd";
import { connect } from "react-redux";
import difference from "lodash/difference";
import http, { getErrorMsg } from "../../utils/http";
import { getIntl } from "../../utils/intl";
import { displayNotification } from '../../components/Notification';
import { FormattedMessage } from 'react-intl';

const { Title, Paragraph } = Typography;

// Customize Table Transfer
const TableTransfer = ({
  leftColumns,
  rightColumns,
  selectedVisitor,
  ...restProps
}) => (
    <div>
      <Title level={4} style={{ marginTop: "12px" }}>
        <FormattedMessage id="VisitorQuestionOrderPage.Title2"
          defaultMessage="Organisieren Sie Ihren Fragen-Flow">
        </FormattedMessage>
      </Title>
      <Paragraph>
        <FormattedMessage id="VisitorQuestionOrderPage.Body2"
          defaultMessage="Hier sehen Sie ein Werkzeug mit zwei Spalten, welches  Fragen-Organisator genannt wird.
          Die erste Spalte ist das Repository Ihrer Fragen. Alle von Ihnen erstellten Fragen werden dort gespeichert.
          Die zweite Spalte ist der Fragenfluss zum zuvor ausgewählten Besuchertyp.
          Wählen Sie die gewünschten Fragen aus der ersten Spalte aus und organisieren Sie diese mit den Pfeilen für jeden Ihrer Besuchertypen.
          Damit stellen Sie sicher, dass Ihre Besucher ab sofort ein einzigartiges Erlebnis in Ihrem Unternehmen hat">
        </FormattedMessage>
      </Paragraph>
      <Transfer
        {...restProps}
        showSelectAll={false}
        listStyle={{
          marginTop: "12px",
          backgroundColor: "#d8dee9",
          border: "0px",
        }}
        titles={[
          <FormattedMessage id="VisitorQuestionOrderPage.TransferTableLeft.Title"
            defaultMessage="Fragen-Repository">
          </FormattedMessage>,
          <div>
            <FormattedMessage id="VisitorQuestionOrderPage.TransferTableRight.Title"
              defaultMessage="Fragen für:{space}" values={{ space: " " }}>
            </FormattedMessage>
            <span style={{ fontWeight: "bold" }}>{selectedVisitor}</span>
          </div>,
        ]}
      >
        {({
          direction,
          filteredItems,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
        }) => {
          const columns = direction === "left" ? leftColumns : rightColumns;
          const rowSelection = {
            getCheckboxProps: (item) => ({ disabled: item.disabled }),
            onSelectAll(selected, selectedRows) {
              const treeSelectedKeys = selectedRows
                .filter((item) => !item.disabled)
                .map(({ key }) => key);
              const diffKeys = selected
                ? difference(treeSelectedKeys, listSelectedKeys)
                : difference(listSelectedKeys, treeSelectedKeys);
              onItemSelectAll(diffKeys, selected);
            },
            onSelect({ key }, selected) {
              onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
          };

          return (
            <Table
              style={{ backgroundColor: "#fff" }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              pagination={false}
              size="default"
              onRow={({ key, disabled: itemDisabled }) => {
                return ({
                  onClick: () => {
                    if (itemDisabled) return;
                    onItemSelect(key, !listSelectedKeys.includes(key));
                  },
                })
              }}
            />
          );
        }}
      </Transfer>
    </div>
  );


const leftTableColumns = [
  {
    dataIndex: "question",
    title: "Text",
  },
  {
    dataIndex: "question_type",
    title: "Question Type",
    render: (tag) => <Tag>{tag}</Tag>,
  },
];
const rightTableColumns = [
  {
    dataIndex: "question",
    title: "Text",
  },
  {
    dataIndex: "question_type",
    title: "Question Type",
    render: (tag) => <Tag>{tag}</Tag>,
  },
];

class TransferQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      targetKeys: [],
      allVisitors: props.allVisitors,
      selectedVisitor: props.selectedVisitor,
      questionRepository: this.props.questionRepository.map((element) => {
        element.key = element.id;
        return element;
      }),
    };

  }

  componentDidMount() {
    this.refresh(this.state.selectedVisitor);
  }

  async refresh(selectedVisitor) {
    this.setState({
      selectedVisitor,
      targetKeys: await this.fetchSelectedFlow(selectedVisitor),
    });
  }

  fetchSelectedFlow = async (selectedVisitor) => {
    const { company_id } = this.props.companyConfig;
    try {
      const response = await http.get(
        `/company/${company_id}/visitor_types/${selectedVisitor}/questions`
      );
      const { payload } = response.data;
      const targetKeys =
        payload.length > 0 ? payload.sort((a, b) => a.rank - b.rank).map((item) => item.id) : [];
      return targetKeys;
    } catch (error) {
      console.error(error);
      const intl = getIntl();
      const title = intl.formatMessage(
        {
          id: "VisitorQuestionOrderPage.Notification.QuestionsFetchError.Title",
          defaultMessage: "Fragen nicht abgeholt"
        }
      );
      displayNotification("error", title, getErrorMsg(error, intl));
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedVisitor !== this.props.selectedVisitor) {
      this.refresh(this.props.selectedVisitor);
    }
  }

  onChange = (nextTargetKeys, direction) => {
    if (direction === "right") {
      const { targetKeys } = this.state;
      const diff = difference(nextTargetKeys, targetKeys);
      const newArray = [...targetKeys, ...diff];
      this.setState({ targetKeys: newArray });
    } else {
      this.setState({ targetKeys: nextTargetKeys });
    }
  };

  submitFlow = async () => {
    const { company_id } = this.props.companyConfig;
    const { selectedVisitor, targetKeys } = this.state;
    const newFlow = targetKeys;

    try {
      await http.post(
        `/company/${company_id}/link_questions`,
        {
          visitor_type_id: selectedVisitor,
          question_ids: newFlow,
        }
      );
      const intl = getIntl();
      const title = intl.formatMessage(
        {
          id: "VisitorQuestionOrderPage.Notification.QuestionTransferSuccess.Title",
          defaultMessage: "Erfolg"
        }
      );
      const body = intl.formatMessage(
        {
          id: "VisitorQuestionOrderPage.Notification.QuestionTransferSuccess.Body",
          defaultMessage: "Ihr Fragen-Flow wurde gespeichert!"
        }
      );
      displayNotification("success", title, body);
    } catch (error) {
      console.error(error);
      const intl = getIntl();
      const title = intl.formatMessage(
        {
          id: "VisitorQuestionOrderPage.Notification.QuestionTransferError.Title",
          defaultMessage: "Fehler beim Speichern des Fragen-Flow"
        }
      );
      const errorResp = getErrorMsg(error, intl, true);
      if (errorResp && errorResp['src'] && errorResp['src']['missing_questions']) {
        const errorDescription = intl.formatMessage(
          {
            id: "VisitorQuestionOrderPage.Notification.QuestionTransferError.Body",
            defaultMessage: "Sie müssen die folgenden Fragen im Fragen-Flow haben:\n"
          }
        )
        const missingQuestions = errorResp['src']['missing_questions'].join('\r\n');
        displayNotification("error", title, errorDescription + missingQuestions);
      }
      else {
        displayNotification("error", title, getErrorMsg(error, intl));
      }
    }
  };

  render() {
    const { targetKeys } = this.state;
    return (
      <div>
        <TableTransfer
          dataSource={this.state.questionRepository}
          targetKeys={targetKeys}
          showSearch={true}
          onChange={this.onChange}
          selectedVisitor={
            this.state.allVisitors.filter(
              visitor => visitor.id === this.state.selectedVisitor
            )[0].type
          }
          filterOption={(inputValue, item) =>
            item.question.toLowerCase().indexOf(inputValue.toLowerCase()) !==
            -1 ||
            item.question_type
              .toLowerCase()
              .indexOf(inputValue.toLowerCase()) !== -1
          }
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
        />
        <Button
          type="primary"
          htmlType="button"
          onClick={this.submitFlow}
          style={{ float: "right", marginTop: "12px" }}
        >
          <FormattedMessage id="VisitorQuestionOrderPage.Transfers.Button.Save"
            defaultMessage="Bestätigen">
          </FormattedMessage>
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyConfig: state.companyConfig,
  };
};

export default connect(mapStateToProps)(TransferQuestion);
