import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, List, Row, Tag, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import VisitorTypeForm from "./forms/VisitorTypeForm";

const { Title } = Typography;

function VisitorTypeCard({
  created_types = [],
  typeOfVisitors,
  createUserType,
  removeVisitorType,
  submitUserTypes,
  visitorRef,
  deleteUserType,
  ...props
}) {
  return (
    <Card className="question-flow-sider">
      <Row key="a" className="p-t-24 p-b-24 p-l-24 p-r-24">
        <Col span={24}>
          <div className="p-b-24">
            <Title level={3} style={{ color: "#4c566a" }}>
              <FormattedMessage
                id="VisitorQuestionFlowPage.VisitorTypePreviouslyCreated.Title"
                defaultMessage="Ihre zuvor erstellten Besuchertypen"
              ></FormattedMessage>
            </Title>
            {created_types.map((created, index) => {
              return (
                <Tag key={index} style={{ padding: "8px", fontSize: "0.9rem" }}>
                  <DeleteOutlined
                    style={{ marginRight: "8px", cursor: "pointer" }}
                    onClick={() => deleteUserType(created.id)}
                  />
                  {created.type}
                </Tag>
              );
            })}
          </div>
          <div className="p-b-24">
            <Title level={3} style={{ color: "#4c566a" }}>
              <FormattedMessage
                id="VisitorQuestionFlowPage.CreateVisitorType.Title"
                defaultMessage="Einen Kundentyp erstellen"
              ></FormattedMessage>
            </Title>
            <VisitorTypeForm
              wrappedComponentRef={visitorRef}
              onSubmit={createUserType}
            />
          </div>
          <div>
            <Title level={4} style={{ color: "#4c566a" }}>
              <FormattedMessage
                id="VisitorQuestionFlowPage.VisitorTypeCreatedNow.Title"
                defaultMessage="Kundentypen"
              ></FormattedMessage>
            </Title>
            <List
              size="large"
              dataSource={typeOfVisitors}
              renderItem={(item) => (
                <List.Item
                  style={{ padding: "12px 0px", display: "inline-block" }}
                >
                  <Tag
                    key={item}
                    closable
                    onClose={() => removeVisitorType(item)}
                    style={{ padding: "6px 12px" }}
                  >
                    {item}
                  </Tag>
                </List.Item>
              )}
            />
            {typeOfVisitors.length > 0 && (
              <div>
                <Button type="primary" onClick={submitUserTypes}>
                  <FormattedMessage
                    id="VisitorQuestionFlowPage.VisitorTypeForm.Button.Save"
                    defaultMessage="Bestätigen"
                  ></FormattedMessage>
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default VisitorTypeCard;
